import React from "react";
import { Container, Text, Title } from "components/commons";
import classnames from "classnames";
import styles from "./fragment.module.scss";

const FragmentA = ({
  children,
  title,
  description,
  customTitle,
  className,
  additionalLeftContent,
  leftPr = "pr-xxl",
}) => {
  return (
    <Container className={classnames("md:flex px-0", className)} paddingless mb="md">
      <div className={classnames("sm:w-full md:w-1/3", leftPr, styles.titleContainer)}>
        {customTitle || (
          <>
            {title && (
              <Title md fontWeight="font-bold">
                {title}
              </Title>
            )}
            {description && <Text>{description}</Text>}
            {additionalLeftContent}
          </>
        )}
      </div>
      <div
        className={classnames(
          "sm:w-full sm:pl-0 w-full mt-md md:mt-0 md:w-2/3 md:pl-xl",
          styles.content
        )}
      >
        {children}
      </div>
    </Container>
  );
};

export default FragmentA;

import {
  Button,
  ButtonLink,
  Field,
  FragmentA,
  Icon,
  Input,
  Modal,
  Panel,
  Text,
  Title,
  Divider,
  Select,
  Toast,
} from "components";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useApi, useForm, useModal } from "hooks";
import lang from "translations";
import { Field as EField, StyleType } from "enums";

import * as siigoApi from "apis/siigo.api";
import { configMapper } from "mappers/siigo.mapper";
import Validation from "services/validation.service";
import { Path } from "paths";
import { getTaxes } from "apis/tax.api";
import { VenueContext } from "contexts";

const SiigoDetailIntegrationModal = ({ isEdit, appId, ...props }) => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const [syncLoading, setSyncLoading] = useState(false);
  const { close } = props;
  const disconnectModal = useModal();

  const initialStateForm = useMemo(() => defaultState, []);
  const { fields, modifyField, modifyForm, validateForm, setPristine, dirty, getFormValues } =
    useForm({
      initialState: initialStateForm,
    });

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [disconnectTxt, setDisconnectTxt] = useState("");
  const [errPM, setErrPM] = useState(false);
  const [errTaxs, setErrTaxs] = useState(false);

  const { mappedData, loading, request } = useApi({
    api: siigoApi.getSyncFromSiigo,
    params: { integrationSiigoId: appId, forceSynced: false },
    mapper: configMapper,
  });
  const saveRequest = useApi({
    api: siigoApi.saveIntegration,
    params: { integrationSiigoId: appId },
  });
  const disconnectRequest = useApi({ api: siigoApi.disconnectSiigo });

  const taxesRequest = useApi({
    api: getTaxes,
    params: { venueId },
    mapper: {
      value: { key: "taxId" },
      text: { key: "taxName" },
      percentage: { key: "taxPercentage" },
    },
    isArray: true,
  });

  const onSyncFromSiigo = async (forceSynced = false) => {
    try {
      setSyncLoading(true);
      const { data } = await request({ forceSynced });
      if (!data) {
        Toast({ content: lang.noDataAvailable, error: true, icon: "exclamation-fill" }).open();
        close();
        return;
      }

      let params = objCleanEmpty({
        // accountName: data?.accountName,
        billingResolution: data?.selectedDocumentType?.id,
        seller: data?.selectedSeller?.id,
        defaultInventoryGroup: data?.selectedDefaultAccountGroup?.id,
        // productTipName: data.selectedProductTips?.name || "",
        // productTipCode: data.selectedProductTips?.code || "",
        // productInventoryGroup: data.selectedProductTips?.accountGroup?.id,
      });
      modifyForm(params, false);
      if (forceSynced)
        Toast({ content: lang.syncFromSiigoSuccessfully, success: true, icon: "check" }).open();
    } catch (error) {
      Toast({
        content: error.messages[0] || lang.somethingWentWrong,
        error: true,
        icon: "exclamation-fill",
      }).open();
      close();
    } finally {
      setSyncLoading(false);
    }
  };

  const onSave = async () => {
    const values = getFormValues();
    let errPaymentMethods = !checkValueArr(paymentMethods);
    let errTaxs = !checkValueArr(taxes);
    setErrPM(errPaymentMethods);
    setErrTaxs(errTaxs);

    if (!validateForm() || errPaymentMethods || errTaxs) {
      return;
    }
    try {
      const params = {
        // accountName: values.accountName,
        selectedSeller: {
          id: values.seller,
        },
        selectedDocumentType: {
          id: values.billingResolution,
        },
        // selectedProductTips: {
        //   name: values.productTipName,
        //   code: values.productTipCode,
        //   accountGroup: {
        //     id: values.productInventoryGroup,
        //   },
        // },
        selectedDefaultAccountGroup: {
          id: values.defaultInventoryGroup,
        },
      };
      if (mappedData.selectedProductTips?.id) {
        params.selectedProductTips.id = mappedData.selectedProductTips?.id;
      }
      params.paymentMethodsMapping = paymentMethods.map(({ name, value }) => ({
        pnId: name,
        siigoId: value,
      }));
      params.taxesMapping = taxes.map(({ name, value }) => ({
        pnId: name,
        siigoId: value,
      }));

      await saveRequest.request(params);
      Toast({
        content: lang.changesSavedSuccessfully,
        success: true,
        icon: "check",
      }).open();
      close();
    } catch (error) {
      Toast({
        content: lang.credentialsAreInvalid,
        error: true,
        icon: "exclamation-fill",
      }).open();
    }
  };

  const onDisconnect = async () => {
    await disconnectRequest.request({ id: appId });
    disconnectModal.close();
    close();
  };

  useEffect(() => {
    if (props.active) {
      onSyncFromSiigo();
      taxesRequest.request();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.active]);

  return (
    <Modal {...props} noHeader width={1190}>
      <div className="flex py-lg justify-between">
        <Title xl className="font-semibold">
          {lang.siigoIntegrationDetail}
        </Title>
        <Icon name="remove" onClick={() => close()} />
      </div>
      <FragmentA title={lang.warning}>
        <Panel mb="0">
          <Text fontWeight="font-bold" color="text-red">
            {lang.pleaseReadThisInstructionCarefully}
          </Text>
          <Text className={"whitespace-pre-wrap mt-4"}>{lang.warningSiigoIntegrationDesc}</Text>
          <Text className={"flex"}>
            {lang.youCanReviewQuestionInEachGuestProfile}
            <span className="ml-1">
              <ButtonLink path={Path.QUESTION_SETTING}>{lang.here}</ButtonLink>
            </span>
          </Text>
        </Panel>
      </FragmentA>
      <Divider />

      <FragmentA title={lang.siigoAccount}>
        <Panel mb="0">
          {/* <Field
            {...fields.accountName}
            labelSuffix={
              <ButtonLink
                size="text-xs"
                newTabPath="https://siigonube.siigo.com/"
                suffix
                icon="arrow-diagonal-right"
                anchor
              >
                {lang.viewAccountOnSiigo}
              </ButtonLink>
            }
          >
            <Input {...fields.accountName} onChange={modifyField} readOnly />
          </Field> */}
          <Field>
            <div className="flex items-center">
              <Text label>{lang.connectStatus}</Text>
              <Icon name={"check"} color={"text-green"} className="ml-16 mr-2" />
              <Text fontWeight="font-semibold">{lang.connected}</Text>
            </div>
            <Text label className={"my-4"}>
              {lang.pleaseFetchDataFromSiigoByClickingButtonBelow}
            </Text>
            {/* <Text label>{lang.syncFromSiigo}</Text> */}
            <Text className={"my-4"}>{lang.syncFromSiigoDesc}</Text>
            <Button loading={syncLoading} onClick={() => onSyncFromSiigo(true)}>
              {lang.syncFromSiigo}
            </Button>
          </Field>
        </Panel>
      </FragmentA>
      <Divider />
      <FragmentA title={lang.invoiceType} description={lang.siigoDescBill} leftPr="pr-0">
        <Panel mb="0">
          <Field {...fields.billingResolution}>
            <Select
              {...fields.billingResolution}
              onChange={(name, obj) => {
                let isDirty = dirty;
                modifyField(name, { value: obj.value });
                if (obj.isAll && !isDirty) {
                  setPristine();
                }
              }}
              options={mappedData?.siigoDocumentTypes || []}
              loading={loading}
              searchable
            />
          </Field>
        </Panel>
      </FragmentA>
      <Divider />
      <FragmentA title={lang.seller} description={lang.siigoDescSeller} leftPr="pr-0">
        <Panel mb="0">
          <Field {...fields.seller}>
            <Select
              {...fields.seller}
              onChange={(name, obj) => {
                let isDirty = dirty;
                modifyField(name, { value: obj.value });
                if (obj.isAll && !isDirty) {
                  setPristine();
                }
              }}
              options={mappedData?.siigoSellers || []}
              loading={loading}
              searchable
            />
          </Field>
        </Panel>
      </FragmentA>
      <Divider />
      {/* <FragmentA title={lang.productForTips} description={lang.siigoDescProductForTips}>
        <Panel mb="0">
          <Field {...fields.productTipName}>
            <Input {...fields.productTipName} onChange={modifyField} />
          </Field>
          <Field {...fields.productTipCode}>
            <Input
              {...fields.productTipCode}
              onChange={modifyField}
              onKeyPress={(event) => {
                if (event.key === " ") {
                  event.preventDefault();
                }
              }}
            />
            <Text label>{lang.codeMustBeUnique}</Text>
          </Field>

          <Field {...fields.productInventoryGroup}>
            <Select
              {...fields.productInventoryGroup}
              onChange={(name, obj) => {
                let isDirty = dirty;
                modifyField(name, { value: obj.value });
                if (obj.isAll && !isDirty) {
                  setPristine();
                }
              }}
              options={mappedData.siigoAccountGroups || []}
              loading={loading}
              searchable
            />
          </Field>
        </Panel>
      </FragmentA>
      <Divider /> */}
      <Title lg className="font-semibold mb-12">
        {lang.productSettings}
      </Title>
      <FragmentA
        title={lang.defaultInventoryGroup}
        description={lang.siigoDescInventoryGroup}
        leftPr="pr-0"
      >
        <Panel mb="0">
          <Field {...fields.defaultInventoryGroup}>
            <Select
              {...fields.defaultInventoryGroup}
              onChange={(name, obj) => {
                let isDirty = dirty;
                modifyField(name, { value: obj.value });
                if (obj.isAll && !isDirty) {
                  setPristine();
                }
              }}
              options={mappedData?.siigoAccountGroups || []}
              loading={loading}
              searchable
            />
          </Field>
        </Panel>
      </FragmentA>
      <Divider />

      <FragmentA title={lang.paymentMethod} description={lang.siigoDescPaymentMethod} leftPr="pr-0">
        <Panel mb="0">
          <div className="w-full grid grid-cols-2 mb-4">
            <div className="col-span-1">
              <Text label>{lang.pouchnationPaymentMethod}</Text>
            </div>
            <div className="col-span-1">
              <Text label>{lang.paymentMethodInSiigo}</Text>
            </div>
          </div>

          {mappedData?.pnPaymentMethods && !!mappedData?.pnPaymentMethods && (
            <PaymentMethodSection
              list={mappedData?.pnPaymentMethods}
              mapping={mappedData?.paymentMethodsMapping}
              options={mappedData?.siigoPaymentMethods || []}
              loading={loading}
              setData={setPaymentMethods}
              err={errPM}
            />
          )}
        </Panel>
      </FragmentA>
      <Divider />

      <FragmentA title={lang.salesTax} description={lang.siigoDescSalesTax} leftPr="pr-0">
        <Panel mb="0">
          <div className="w-full grid grid-cols-2 mb-4">
            <div className="col-span-1">
              <Text label>{lang.pouchnationTax}</Text>
            </div>
            <div className="col-span-1">
              <Text label>{lang.mapToSiigo}</Text>
            </div>
          </div>

          {mappedData?.pnTaxes && !!mappedData?.pnTaxes && (
            <TaxesSection
              // list={mappedData?.pnTaxes}
              list={taxesRequest.mappedData}
              mapping={mappedData?.taxesMapping}
              options={mappedData?.siigoTaxes || []}
              loading={loading}
              setData={setTaxes}
              err={errTaxs}
            />
          )}
        </Panel>
      </FragmentA>
      <Divider />
      <div className="flex justify-between pb-5">
        <Button type={StyleType.Danger} onClick={() => disconnectModal.show()}>
          {lang.disconnect}
        </Button>
        <div>
          <Button type={StyleType.Secondary} className="mr-4" onClick={close}>
            {lang.discard}
          </Button>
          <Button onClick={onSave} disabled={saveRequest.loading} loading={saveRequest.loading}>
            {lang.save}
          </Button>
        </div>
      </div>
      <Modal {...disconnectModal} noHeader>
        <div className="flex py-lg justify-between">
          <Title>{`Disconnect Siigo?`}</Title>
          <Icon name="remove" onClick={disconnectModal.close} />
        </div>

        <Text label className="mt-xs">
          {lang.youAreAboutToDisconnect}
        </Text>

        <Text fontWeight="font-semibold" color="text-gray">
          {lang.typeDisconnect}
        </Text>
        <Input
          value={disconnectTxt}
          onChange={(_, { value }) => setDisconnectTxt(value)}
          error={disconnectTxt && disconnectTxt !== "DISCONNECT"}
        />

        <div className="flex my-md justify-end gap-2 pb-4">
          <Button type={StyleType.Secondary} onClick={disconnectModal.close}>
            {lang.cancel}
          </Button>
          <Button
            loading={disconnectRequest.loading}
            disabled={disconnectTxt !== "DISCONNECT"}
            type={StyleType.Danger}
            onClick={onDisconnect}
          >
            {lang.disconnect}
          </Button>
        </div>
      </Modal>
    </Modal>
  );
};

const defaultState = {
  // accountName: {
  //   name: "accountName",
  //   label: lang.accountName,
  //   required: true,
  //   value: "",
  //   type: EField.INPUT,
  // },
  billingResolution: {
    name: "billingResolution",
    label: lang.invoiceType,
    required: true,
    value: "",
    type: EField.ANY,
    placeholder: lang.selectInvoiceType,
    validations: [Validation.required()],
  },
  seller: {
    name: "seller",
    label: lang.seller,
    required: true,
    value: "",
    type: EField.ANY,
    placeholder: lang.selectSeller,
    validations: [Validation.required()],
  },
  defaultInventoryGroup: {
    name: "defaultInventoryGroup",
    label: lang.defaultInventoryGroup,
    required: true,
    value: "",
    type: EField.ANY,
    placeholder: lang.selectInventoryGroup,
    validations: [Validation.required()],
  },
  // productTipName: {
  //   name: "productTipName",
  //   label: lang.accountName,
  //   required: true,
  //   value: "",
  //   type: EField.INPUT,
  //   validations: [Validation.required()],
  // },
  // productTipCode: {
  //   name: "productTipCode",
  //   label: lang.code,
  //   required: true,
  //   value: "",
  //   type: EField.INPUT,
  //   validations: [Validation.required()],
  // },
  // productInventoryGroup: {
  //   name: "productInventoryGroup",
  //   label: lang.inventoryGroup,
  //   required: true,
  //   value: "",
  //   type: EField.ANY,
  //   placeholder: lang.selectProductForTips,
  //   validations: [Validation.required()],
  // },
};

const PaymentMethodSection = ({
  list = [],
  mapping = [],
  options,
  loading,
  setData,
  err = false,
}) => {
  const paymentStateForm = useMemo(() => {
    const paymentMethod = {};
    list
      .filter((i) => i.text === "PouchPAY")
      .forEach((e) => {
        let value = mapping.find(({ pnId }) => e.value === pnId)?.siigoId || "";
        paymentMethod[e.value] = {
          name: e.value,
          id: e.value,
          label: e.text,
          required: true,
          value,
          type: EField.ANY,
        };
      });
    return paymentMethod;
  }, [list, mapping]);

  const { fields, modifyField } = useForm({
    initialState: paymentStateForm,
  });

  useEffect(() => {
    const data = Object.values(fields).map(({ name, value }) => {
      return { name, value };
    });
    setData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <>
      {Object.values(fields).map((e, i) => (
        <div key={e.label}>
          {!!i && <Divider my="my-4" />}
          <div className="w-full grid grid-cols-2">
            <div className="col-span-1 flex items-center">
              <Text>{e.label}</Text>
            </div>
            <div className="col-span-1">
              <Field
                {...e}
                noLabel
                error={err && !e.value}
                message={err && !e.value && lang.thisFieldIsRequired}
              >
                <Select
                  {...e}
                  onChange={(name, obj) => {
                    modifyField(name, { value: obj.value });
                  }}
                  placeholder={lang.chooseAPaymentMethod}
                  options={options}
                  loading={loading}
                  searchable
                  error={err && !e.value}
                />
              </Field>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

const TaxesSection = ({ list = [], mapping = [], options, loading, setData, err = false }) => {
  const taxesStateForm = useMemo(() => {
    const taxes = {};
    list.forEach((e) => {
      let value = mapping.find(({ pnId }) => e.value === pnId)?.siigoId || "";
      taxes[e.value] = {
        name: e.value,
        id: e.value,
        label: e.text,
        required: true,
        value,
        type: EField.ANY,
        percentage: e.percentage,
        validations: [Validation.required()]
      };
    });
    return taxes;
  }, [list, mapping]);

  const { fields, modifyField, dirty, setPristine } = useForm({
    initialState: taxesStateForm,
  });

  useEffect(() => {
    const data = Object.values(fields).map(({ name, value }) => {
      return { name, value };
    });
    setData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <>
      {Object.values(fields).map((e, i) => (
        <div key={e.label}>
          {!!i && <Divider my="my-4" />}
          <div className="w-full grid grid-cols-2">
            <div className="col-span-1 flex items-center">
              <Text>
                {e.label} ({e.percentage}%)
              </Text>
            </div>
            <div className="col-span-1">
              <Field
                {...e}
                noLabel
                error={err && !e.value}
                message={err && !e.value && lang.thisFieldIsRequired}
                onChange={() => {
                  let isDirty = dirty;
                  modifyField(e.name, { value: e.value });
                  if (!isDirty) setPristine();
                }}
              >
                <Select
                  {...e}
                  onChange={(name, obj) => {
                    modifyField(name, { value: obj.value });
                  }}
                  placeholder={lang.chooseASaleTax}
                  options={options.filter((i) => i.percentage === e.percentage)}
                  loading={loading}
                  searchable
                  error={err && !e.value}
                />
              </Field>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

const checkValueArr = (arr = []) => {
  let ret = true;
  arr.forEach(({ value }) => {
    if (!value) {
      ret = false;
      return;
    }
  });
  return ret;
};

const objCleanEmpty = (obj = {}) => {
  const ret = {};
  Object.entries(obj).forEach(([k, v]) => {
    if (v) ret[k] = { value: v };
  });
  return ret;
};

export default SiigoDetailIntegrationModal;

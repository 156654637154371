export const locale = {
  aWholeProductIsFinished:
    "A whole product is a finished product to be sold in your venue. With variants, you may choose to sell different variations of this product such as flavor, color, and size. All whole products and it’s variants would each have it’s own supply item.",
  accepted: "Accepted",
  acceptedByDian: "Accepted by Dian",
  accessControl: "Access control",
  accessControlReport: "Access Control Report",
  accessControlRoleAppDesc: "This role does not have access to the online Venue Dashboard.",
  accessControlRoleDashboardDesc: "Allow access to Venue App’s Check Access.",
  accessKey: "Access Key",
  accessPoint: "Access Point",
  accessPointCreateSuccess: " Access point created successfully",
  accessPointExisted: "This access point already existed. Choose a different name",
  accessPointName: "Access Point Name",
  accessToken: "Access Token",
  accessURLOrQRCode: "Access URL or QR Code",
  accommodation: "Accommodation",
  account: "Account",
  accountAlreadyExists: "This email is already associated with an account in this venue",
  accountCantSeemToFind:
    "Oh no! We can't seem to find your account. Please contact the venue manager for assistance.",
  accountCode: "Account Code",
  accountCodeAddedName: "Account Code “{0}“ added.",
  accountCodeAlreadyExist: "Account code already exists.",
  accountEmailAdded: "Account “{0}“ added.",
  accountEmailRemoved: "Account “{0}“ removed.",
  accountIsActive: "Account is Active",
  accountMapping: "Account Mapping",
  accountMappingDescription: "Set and map your accounts to the PouchVENUE system.",
  accountName: "Account Name",
  accountNotFound: "Account not found",
  accountSettings: "Account Settings",
  accountType: "Account Type",
  accountingSoftware: "Accounting Software",
  accounts: "Accounts",
  accrNo: "Accr#:",
  actions: "Actions",
  activateInPos: "Activate in POS",
  activateOnOnlineMenu: "Activate on Online Menu",
  activateOnPOS: "Activate on Point-of-Sale (POS)",
  activateOnPos: "Activate on Point-of-Sale (POS)",
  activateVariant: "Activate Variant",
  active: "Active",
  activeDateRange: "Active Date Range",
  actualClosingBalance: "Actual Closing Balance",
  actualQty: "Actual Qty",
  actualStockQty: "Actual Stock Qty",
  add: "Add",
  addANote: "Add a note...",
  addAProduct: "Add a Product",
  addASupplyItem: "Add a Supply Item",
  addAValidAttributeAndOptions: "Add a valid attribute and options.",
  addAWholeProduct: "Add a Whole Product",
  addAccount: "Add Account",
  addAndManageAdditionalCharge: "Add and manage additional charges for Sell on POS.",
  addAnotherAccount: "Add another account",
  addAnotherAttribute: "Add Another Attribute",
  addAnotherProductOrSupplyItem: "Add another product or supply item",
  addAnotherSupplyItem: "Add Another Supply Item",
  addCharge: "Add Charge",
  addCustomQuestion: "Add Custom Question",
  addNewAccount: "Add new account",
  addNewAccountCode: "Add new account code",
  addNewAccountCodeName: "Add new account code “{0}”",
  addNewPaymentMethod: "Add New Payment Method",
  addNewSupplyItem: "Add New Supply Item",
  addNewSupplyItemSelect: "Add New Supply Item “{0}”",
  addNewTax: "Add New Tax",
  addNote: "Add Note",
  addOption: "Add Option",
  addOptions: "Add Options",
  addPaymentMethod: "Add Payment Method",
  addProduct: "Add Product",
  addProductLimit:
    "To add more products, try deleting some products to create new ones or ask your Venue Owner to increase your product limit!",
  addProductLimitOwner:
    "To add more products, try deleting some products to create new ones or upgrade your plan to increase your product limit!",
  addProducts: "Add products or supply items in this product set.",
  addQty: "Add Qty",
  addStaffLimit:
    "To add more staff, try to remove some staff or ask your Venue Owner to increase your staff limit!",
  addStaffLimitOwner:
    "To add more staff, try to remove some staff or upgrade your plan to increase your staff limit!",
  addSupplyItem: "Add Supply Item",
  addThisProduct: "Add this product as a new product and supply item.",
  addVariant: "Add Variant",
  addedStock: "Added Stock",
  additionalChargeReport: "Additional Charge Report",
  additionalCharges: "Additional Charges",
  adjustQuantity: "Adjust Quantity",
  adjustStockSuccessfully: "Adjust Stock successfully",
  adjustTheSupplyItemsVariant:
    "Adjust the supply items and the quantities for this specific variant.",
  advancedReporting: "Advanced Reporting",
  all: "All",
  allAccessPoints: "All Access Points",
  allAccountTypes: "All Account Types",
  allCategories: "All Categories",
  allDevices: "All Devices",
  allDevicesUsed: "All Devices Used",
  allDiscounts: "All Discounts",
  allGuestProfiles: "All Guest Profiles",
  allLocation: "All Location",
  allLocations: "All Locations",
  allMeasurements: "All Measurements",
  allPaymentMethods: "All Payment Methods",
  allProducts: "All Products",
  allReportsReadyForDownload: "All reports ready for download",
  allResponse: "All Response",
  allRightsReserved: "All rights reserved.",
  allRoles: "All Roles",
  allSafeToDeleteLocation: "All safe! There are no products with this location.",
  allSelectedAvailablePos: "All selected products will be available on POS.",
  allSelectedNotPos: "All selected products will not be shown on your POS.",
  allShiftsClosed: "All Shifts Closed",
  allSources: "All Sources",
  allStaff: "All Staff",
  allState: "All State",
  allStatus: "All Status",
  allStockLevels: "All Stock Levels",
  allTransactionTypes: "All Transaction Types",
  allTransactions: "All Transactions",
  allVenues: "All Venues",
  allVoucherTypes: "All Voucher Types",
  allVouchers: "All Vouchers",
  allowAccess: "Allow Access",
  allowAccessAndConnect: "Allow Access and Connect",
  allowAndSetTip: "Allow tip and set percentages",
  allowBargainDesc: "By checking this, staff can change the price of the item in POS",
  allowBargainOnThisProduct: "Allow this product to be bargained",
  allowCustomTip: "Allow guests to add custom tip",
  allowGuestProfile: "Allow Guest Profile",
  allowGuestWBKeeping: "Allow guests to keep their tags upon check-out.",
  allowInstallationFromUnknownSources: "Allow installation from unknown sources",
  allowInstallationFromUnknownSourcesDesc:
    "You may need to enable this setting in your device's security settings to install apps outside of the official app store. The exact steps might vary depending on your device's operating system",
  allowOrRestrictGuestWBKeeping: "Allow or restrict guests from keeping their tags upon check-out.",
  allowPostpaidAndLimit: "Allow Postpaid Credits and set limits.",
  allowPostpaidInGuestProfile:
    "Allow this guest profile to make purchases without credits. They will be charged when they check-out from the venue.",
  allowThisGuestProfileLimit:
    "Allow this guest profile to make purchases without credits. They will be charged when they check-out from the venue.",
  allowedAccessPoint: "Allowed Access Point",
  allowedGuestProfile: "Allowed Guest Profile",
  allowedPaymentMethod: "Allowed Payment Methods",
  almostThere: "Almost there!",
  alreadyExists: "Already exists.",
  amount: "Amount",
  anHourAgo: "an hour ago",
  and: "and",
  andManyMore: "and many more!",
  andWouldBe: "and would be",
  anyMoreQuestions: "Any more questions? {0}",
  appDate: "App Install / Update Date",
  appReinstall: "App Reinstall",
  appVersion: "App Version",
  applyFilter: "Apply Filter",
  areYouSureSentToDian:
    "Are you sure you want to send this invoice to Dian? Please double-check the recipient before proceeding.",
  areYouSureYouWantToDeleteCharge: "Are you sure you want to delete Charge item {0}",
  areYouSureYouWantToDeleteLocation:
    "Are you sure you want to delete {0}? This location currently has an inventory value of {1}.",
  areYouSureYouWantToDeleteProduct:
    "Are you sure you want to delete {0}? This supply item currently has {1} {2} in stocks.",
  areYouSureYouWantToDeleteProduct2:
    "Are you sure you want to delete {0}? It will no longer be available in your venue. Previous transactions and reports would not be affected.",
  areYouSureYouWantToRemoveVariant:
    "Are you sure you want to remove the “{0}” variant? Its supply item would not be removed.",
  arrivalDate: "Arrival Date",
  arrivals: "Arrivals",
  askDeleteGuestProfile: "Delete guest profile?",
  askDeleteStaffProfile: "Delete staff profile?",
  askGuestInfoCheckIn: "Ask guest's information during check-in.",
  askVenueOwnerToUpgradePlan:
    "Ask the venue owner to upgrade your plan and start using this feature.",
  assemble: "Assemble",
  assembleAProduct: "Assemble a Product",
  asset: "Asset",
  assignAProfile: "Assign a profile",
  assignFreeCreditsStaff: "Assign free credits to staff in this group",
  assignVoucherStaff: "Assign the vouchers to staff in this group",
  assignVoucherToGuest: "Assign the vouchers to guests in this group upon registration.",
  assignedRooms: "Assigned Rooms/Beds",
  assignedSpace: "Assigned Space",
  atLeast1GuestProfile: "At least 1 Guest Profile is required",
  attribute: "Attribute",
  attributeAlreadyExists: "Attribute name already exists in this product",
  automaticallyAddThisChargeToTheCart: "Automatically add this charge to the cart?",
  automaticallyAddThisChargeToTheCartDesc:
    "Once the first item is added to the cart, this charge will automatically be added. Staff can remove this charge.",
  automaticallyGenerateSku: "Automatically Generate SKU",
  availability: "Availability",
  availabilityOnPos: "Availability on POS",
  available: "Available",
  availableAnytime: "Available anytime",
  availableEveryday: "Available everyday",
  availableOnPos: "Available on Point-of-Sale (POS)",
  averageAmountPerItem: "Ave. Amount per Item",
  averageDurationOfStay: "Average Duration of Stay",
  averageOrderValue: "Average Order Value",
  awaiting: "Awaiting",
  awaitingInviteResponse: "Awaiting invite response.",
  back: "Back",
  backOffice: "Back Office",
  backOfficeRoleAppDesc:
    "This role has no roles related to the App and would not be able to login on the device.",
  backOfficeRoleDashboardDesc:
    "Allows access to Venue Dashboard’s Insights, Selling, Inventory, Locations, Staffs, Guest Profile, Vouchers, Discounts, Printer Profile, and Venue’s Settings",
  balance: "Balance",
  balanceMovementsDescription:
    "See which venue within the venue group the balance of the guests came from",
  balanceMovementsIn: "Balance Movements In",
  balanceMovementsInPage: "Balance Movements In",
  balanceMovementsOut: "Balance Movements Out",
  balanceMovementsOutPage: "Balance Movements Out",
  banATag: "Ban a Tag",
  banDetails: "Ban Details",
  banList: "Ban List",
  banTag: "Ban Tag",
  banTagListSettings: "Ban Tag List Settings",
  banTags: "Ban Tags",
  bannedBy: "Banned By",
  bannedTags: "Banned Tags",
  banningThisTagWillOnlyTakeEffect:
    "Banning this tag will only take effect next time your devices are synced and updated (usually every 5 minutes when connected to internet). To reflect these changes immediately, please sync and update your devices once you ban this tag.",
  bargainAllowed: "Bargain Allowed",
  bargainRequiresApproval: "Bargain Requires Approval",
  bargainRequiresApprovalOnThisProduct:
    "Require manager authorization when changing this item’s price on POS",
  basicInfo: "Basic Info",
  beforeUpdatingTheAppPleaseEnsureAllDataIsSyncedBy:
    "Before updating the app, please ensure all data is synced by:",
  belowParLevel: "Below Par Level",
  billAmount: "Bill Amount",
  billedTo: "Billed To",
  birthdate: "Birthdate",
  birthday: "Birthday",
  bookedBy: "Booked by",
  bookingAccomodation: "Booking Accomodation",
  bookingAmount: "Booking Amount",
  bookingDescription: "Keep track of every booking reservation in your venue.",
  bookingDetails: "Booking Details",
  bookingGuests: "Booking Guests",
  bookingId: "Booking ID",
  bookingList: "Booking List",
  bookingNo: "Booking No.",
  bookingPrice: "Booking Price",
  bookingStatus: "Booking Status",
  bookingSummary: "Booking Summary",
  bookings: "Bookings",
  bottle: "bottle",
  browseProducts: "Browse Products",
  browseVouchers: "Browse Vouchers",
  businessAddress: "Business Address",
  businessContactNumber: "Business Contact Number",
  businessDay: "Business Day",
  businessDetails: "Business Details",
  businessName: "Business Name",
  byAssemblingAProduct:
    "By assembling a product, you can create a product with multiple supply items as well as breakdown a single supply item with variants.",
  byCreatingPrinterProfiles:
    "By creating printer profiles, you can set which products to print on orders slips for your different venue stations such as kitchens, bars, etc.",
  cacheCleared: "Cache Cleared",
  cancel: "Cancel",
  cancelBanTag: "Cancel Ban Tag",
  cancelCloseShift: "Cancel Close Shift",
  cancelled: "Cancelled",
  cannotBeDeleted: "Cannot be deleted.",
  cannotDeletePartOfComposite: "{0} cannot be deleted as it is part of {1} composite {2}.",
  cantFindDesc: "Can’t find what you’re looking for? Try a different search or filter.",
  capacity: "Capacity",
  card: "Card",
  cardApprovalNumber: "Card Approval No",
  cardNumber: "Card No",
  cash: "Cash",
  cashSales: "Cash Sales",
  cashlessTransactionsWithDoor: "cashless transactions with door lock activation! ",
  categories: "Categories",
  categoriesDeleted: "{0} categories deleted.",
  categorizeAndManage: "Categorize and manage every transaction with chart of accounts.",
  category: "Category",
  categoryCreated: 'Category "{0}" created.',
  categoryDeleted: 'Category "{0}" deleted.',
  categoryList: "Category List",
  categoryName: "Category Name",
  categoryNameAlreadyExist: "Category name already exists.",
  categoryNameReserved: "Category name is reserved. Please use other name.",
  categoryReport: "Category Report",
  categoryUpdated: 'Category "{0}" updated.',
  change: "Change",
  changePassword: "Change Password",
  changePhoto: "Change Photo",
  changelog: "Changelog",
  changesSaved: "Changes saved.",
  changesSavedSuccessfully: "Changes saved successfully",
  changingCurrencyWontAffect:
    "Changing currency won’t affect the value price of your products, only their currency symbols.",
  charge: "Charge",
  chargeAmount: "Charge Amount",
  chargeCalculation: "Charge Calculation",
  chargeDeleted: "Charge {0} Deleted",
  chargeItem: "Charge Item",
  chargeName: "Charge Name",
  chargeTaxesOnThisProduct: "Charge taxes on this product",
  chartOfAccounts: "Chart of Accounts",
  chartOfAccountsDescription: "Update and manage your chart of accounts.",
  checkInDate: "Check-in Date",
  checkInDetails: "Check-in Details",
  checkInQuestions: "Check-in Questions",
  checkInSettings: "Check-In Settings",
  checkIns: "Check-ins",
  checkOutDetails: "Check-out Details",
  checkOutTheGuide: "Check out the guide to connect integrations.",
  checkOuts: "Check-outs",
  checkStocks: "Check Stocks",
  checkYourEmail: "Check your email",
  checkYourEmailAt: "Check your email at {0} to complete setting up your account.",
  checkYourEmailOrAdmin: "Check your email or contact your administrator.",
  checkbox: "Checkbox",
  checkedIn: "Checked-in",
  checkedInBy: "Checked-in by",
  checkedInDate: "Checked-In Date",
  checkedOut: "Checked-out",
  checkedOutBy: "Checked-out by",
  checkedOutDate: "Checked-Out Date",
  checkedOutOn: "Checked-out on",
  checkinAmount: "Check-in Amount",
  checkinId: "Check-in ID",
  checkingYourInternet: "Checking your internet or data connectivity.",
  checkout: "Check-out",
  checkoutOurEbook: "Check out our ebook as well on how to optimize your venue!",
  chooseAInventoryGroup: "Choose a inventory group",
  chooseAPassword: "Use a strong password that you don’t use elsewhere.",
  chooseAPaymentMethod: "Choose a Payment Method",
  chooseAReason: "Choose a reason",
  chooseASaleTax: "Choose a sale tax",
  chooseASimpleOrMultipleItem: "Choose a single or multiple supply items to create a product.",
  chooseAVenue: "Choose a venue",
  chooseAtleast1Location: "Choose at least 1 location",
  chooseCategoryColor: "Choose a category color:",
  chooseColumns: "Choose which columns you’d like to see on this report.",
  chooseInformation: "Choose which information to include in the sales breakdown.",
  chooseLocationForThisStaff: "Choose location designation(s) for this staff",
  choosePassword: "Choose password",
  choosePaymentMethod: "Choose Payment Method",
  chooseProductsCanBeDiscounted: "Choose which products from your inventory can be discounted.",
  chooseRole: "Choose role(s) for this staff",
  chooseTheLocationWhereThisProduct:
    "Choose the locations where this product is sold and where the supplies are deducted.",
  chooseVouchers: "Choose Vouchers",
  cityStateCountry: "City, State, Zip Code",
  clear: "Clear",
  clearAllFilters: "Clear All Filters",
  clearDownloads: "Clear Downloads",
  clearedTags: "Cleared Tags",
  clearedUnusedAndExpiredCredits: "Cleared, Unused, and Expired Credits",
  clearedUnusedAndExpiredCreditsMessage:
    "For credits that were topped up but were cleared, non-refundable, or expired.",
  clearedUnusedAndExpiredCreditsPlaceholder: "Other Income (expiry)",
  clearedUnusedExpiredCredit: "Other Income (Expiry)",
  clickHere: "click here",
  clickHereToRetry: "Click here to retry.",
  close: "Close",
  closePreview: "Close Preview",
  closeReceiptPreview: "Close Receipt Preview",
  closeShift: "Close Shift",
  closeShiftWarning:
    "Make sure all transactions are synced before forcing the shift close and only use this if you are unable to close the shift in the app.",
  cloudbeds: "Cloudbeds",
  code: "Code",
  codeMustBeUnique: "Code must be unique",
  coffeeBeans: "coffee beans",
  columns: "Columns",
  combineYourProducts: "Combine your products to be sold as a set.",
  comingSoon: "Coming Soon",
  communityGuidelines: "community guidelines",
  companyDetails: "Company Details",
  companyRegistrationNo: "Company Registration No.",
  completeStocktake: "Complete Stocktake",
  completeStocktakeConfirmation: "Complete {0}?",
  completeSubscription: "Complete Subscription",
  completeYourSubscription: "Complete your subscription to access your venue, ",
  completed: "Completed",
  completedBy: "Completed by",
  completedOn: "Completed on",
  composite: "Composite",
  composition: "Composition",
  compositionGuide: "Composition Guide",
  confirm: "Confirm",
  confirmImport: "Confirm Import",
  confirmImportDesc: "Are you sure you want to import supply items?",
  confirmPassword: "Confirm password",
  confirmStockAdjustment: "Confirm Stock Adjustment",
  confirmTransfer: "Confirm Transfer",
  confirmationPending: "Confirmation Pending",
  confirmed: "Confirmed",
  connect: "Connect",
  connectAndInstall: "Connect and Install",
  connectPurchasePlus: "Connect Purchase Plus to your PouchNATION account.",
  connectStatus: "Connect Status",
  connected: "Connected",
  connectedSuccessfully: "Connected Successfully",
  contactNumber: "Contact Number",
  contactUs: "contact us",
  contactUs2: "Contact Us",
  continue: "Continue",
  continueAs: "Continue as {0}",
  continueBanningThisTag: "Continue banning this tag?",
  continuingOnThisPageWillLogout: "Continuing on this page will logout to your current session.",
  copied: "Copied!",
  copiedCUEFnumber: "Copied CUEF number",
  copiedCUFEnumber: "Copied CUFE number",
  copy: "Copy",
  copyLink: "Copy Link",
  copyTheApkDownloadUrl:
    "Copy the APK download URL provided above or scan the QR code with a QR scanner app.",
  copyrightYear: "Copyright © 2024",
  costOfGoodsSold: "Cost of Goods Sold (COGS)",
  costOfGoodsSoldMessage: "The default account for the cost of a supply once sold.",
  costOfGoodsSoldPlaceholder: "COGS/COGS",
  country: "Country",
  createALocation: "Create a Location",
  createAccessPoint: "Create Access Point",
  createAccount: "Create Account",
  createAccountCode: "Create account code",
  createAndManageProducts: "Create and manage products that are sellable in your locations",
  createAndManageRawMaterials:
    "Create and manage raw material items and its inventory stocks for each location.",
  createAndManagerDiscounts: "Create and manage your discounts with discounted products",
  createAndManagerVouchers: "Create and manage your vouchers that will be used to redeem products",
  createCategoriesToLink: "Create categories to link and categories your products",
  createCategory: "Create Category",
  createDiscountPage: "Create Discount Page",
  createLocation: "Create Location",
  createLocationToStoreYourProducts: "Create a location to store your products.",
  createNewCategory: "Create New Category",
  createNewCharge: "Create New Charge",
  createNewDiscount: "Create New Discount",
  createNewGuestProfile: "Create New Guest Profile",
  createNewLocation: "Create New Location",
  createNewProduct: "Create New Product",
  createNewProductSet: "Create New Product Set",
  createNewProfile: "Create New Profile",
  createNewStaffProfile: "Create New Staff Profile",
  createNewStocktake: "Create New Stocktake",
  createNewSupplyItem: "Create New Supply Item",
  createNewVoucher: "Create New Voucher",
  createNote: "Create Note",
  createPrinterProfile: "Create Printer Profile",
  createProduct: "Create Product",
  createProductSet: "Create Product Set",
  createStaffProfile: "Create Staff Profile",
  createStaffWhoCan:
    "Create staff who can topup credits, register guests, and sell on your venues.",
  createTheProduct: "Create the products to sell in your venue.",
  createVenue: "Create Venue",
  createVenuePage: "Create Venue Page",
  createVoucherPage: "Create Voucher Page",
  createVouchersForGuest: "Create vouchers for your guests and staff use!",
  createYourOwnIntegration: "Create your own integrations with PouchCONNECT",
  createYourVenue: "Create Your Venue",
  createdSupplyItem: "Created Supply Item",
  credentialsAreInvalid:
    "Oops, some information are invalid. Please enter the correct data and try again.",
  credit: "Credit",
  creditAutomaticallyExpires: "Credits automatically expires after check-out",
  creditBalance: "Credit Balance",
  creditCard: "Credit Card",
  creditDebit: "Credit/Debit",
  creditDebitSales: "Credit/Debit Sales",
  creditLimits: "Credit Limits",
  creditSettings: "Credit Settings",
  credits: "Credits",
  creditsDescription:
    "Assign free credits to guests in this group and set their postpaid credit limit.",
  creditsExpiration: "Credits Expiration",
  creditsExpirationDescription: "Set credits to expire when a guest is checked-out.",
  creditsExpirationOnCheckout: "Credits Expiration on Check-out",
  creditsKept: "Credits Kept",
  creditsKeptByGuests: "Credits Kept by Guests",
  creditsLiabilityAccount: "Credits Liability Account",
  creditsLiabilityAccountMessage:
    "The holding account for all credits that are topped up in a tag before sales.",
  creditsLiabilityAccountPlaceholder: "Liability/Credits",
  creditsNeverExpires: "Credits never expires after check-out.",
  creditsReceived: "Credits Received",
  creditsReceivedFromVenues: "Credits Received from other Venues",
  creditsWouldNeverExpire: "Credits would never expire when a guest is currently checked-in.",
  cufe: "CUFE",
  currency: "Currency",
  currencyAndFormat: "Currency and Format",
  currentGuestProfiles: "Current Guest Profiles",
  currentPassword: "Current Password",
  currentPasswordAndNewPasswordMatched: "Current and new password is matched",
  currentQty: "Current Qty",
  custom: "Custom",
  customDateTime: "Custom Date and Time Range",
  customFields: "Custom Fields",
  customLabel: "Custom Label",
  customName: "Custom Name",
  customNameIsAlreadyExisting: "Custom name is already existing.",
  customQuestion: "Custom Question",
  customQuestions: "Custom Questions",
  customTax: "Custom Tax",
  customerBehaviourOverTime: "Customer Behaviour Overtime",
  customizeReceipt: "Customize each section of your receipt.",
  customizeSectionReceipt: "Customize the sections of your receipt.",
  daily: "Daily",
  dashboard: "Dashboard",
  dataError: "Data Error",
  dataIncorrectly: "Data Incorrectly",
  date: "Date",
  dateAndTime: "Date and Time",
  dateAndTimeOfTransaction: "Date and Time of Transaction",
  dateBanned: "Date Banned",
  dateCreated: "Date Created",
  dateIssuedWithColonSymbol: "Date Issued:",
  dateModified: "Date Modified",
  dateOfCheckin: "Date of Check-in",
  dateOfCheckout: "Date of Check-out",
  dateOfRegistration: "Date of Registration",
  dateRange: "Date Range",
  day: "day",
  dayEnd: "Day End",
  dayStart: "Day Start",
  daySummary: "Day Summary",
  days: "days",
  daysAfterCheckout: "days after check-out",
  debitCredit: "Debit / Credit Card",
  default: "Default",
  defaultAccountForTaxes: "The default account for taxes charged for each product.",
  defaultFields: "Default Fields",
  defaultInventoryGroup: "Default Inventory Group",
  defaultMode: "Default Mode",
  defaultModeSettingDesc: "By default, the mobile app will be set to this mode:",
  defaultQuestions: "Default Questions",
  defaultTableName: "Default Table Name",
  defaultTax: "Default Tax",
  defectiveTag: "Defective Tag",
  defectiveTags: "Defective Tags",
  delete: "Delete",
  deleteAProduct: "Delete Product",
  deleteAProduct2: "Delete Product?",
  deleteAProductSet: "Delete Product Set",
  deleteAccessPoint: "Delete Access Point",
  deleteAccessPointDesc: "Are you sure you want to delete this access point? ",
  deleteCategories: "Delete {0} categories",
  deleteCategory: "Delete Category",
  deleteCharge: "Delete Charge",
  deleteChargeFailed: "Unable to delete charge, please try again later",
  deleteCurrentlyBeingUsed:
    "{0} is currently being used in some product sets. To continue, please remove this product from the following product sets",
  deleteDiscount: "Delete Discount",
  deleteGuestProfile: "Delete Guest Profile",
  deleteLocation: "Delete Location",
  deleteLocationNote: "Note: Your previous transactions with this location will be retained.",
  deleteName: "Delete “{0}“?",
  deleteNote: "Delete Note",
  deletePaymentMethod: "Delete Payment Method",
  deletePrinterProfile: "Delete Printer Profile",
  deleteProduct: "Delete {0}?",
  deleteProductSet: "Delete Product Set?",
  deleteProducts: "Delete {0} products?",
  deleteProducts2: "Delete {0} products",
  deleteQuestion: "Delete Question",
  deleteSelectedCategories: "Delete selected categories",
  deleteSelectedCategory: "Delete selected category",
  deleteSelectedProduct: "Delete selected product",
  deleteSelectedProducts: "Delete selected products",
  deleteStaff: "Delete Staff",
  deleteStaffModalDesc:
    "You are about to remove staff(s). These staffs will no longer have access to Venue. This action can not be undone.",
  deleteStaffModalNote: "Note: Your previous transactions with these staff will be retained",
  deleteStaffProfile: "Delete Staff Profile",
  deleteStaffSuccessfully: "Delete Staff Successfully",
  deleteSupplyItem: "Delete Supply Item",
  deleteTax: "Delete Tax",
  deleteThis: "Delete this {0}?",
  deleteThisMultiple: "Delete {0} {1}?",
  deleteThisQuestion: "Delete this question?",
  deleteVoucher: "Delete Voucher",
  deleted: "Deleted",
  deletedPaymentMethod: "Payment method “{0}“ deleted.",
  deletedSuccessfully: "Deleted Successfully",
  deletedTax: "Tax “{0}“ deleted.",
  deletingLoading: "Deleting...",
  deletingName: "Deleting {0}",
  deletingThisLocation:
    "Deleting this location would also remove the stock balances of all supply items. This action cannot be undone.",
  denominations: "Denominations",
  departure: "Departure",
  departureDate: "Departure Date",
  describeThisProduct: "Describe this product...",
  describeThisProductSet: "Describe this product set",
  description: "Description",
  destinationLocation: "Destination location",
  destinationLocationNewQty: "Destination location new qty",
  device: "Device",
  deviceId: "Device ID",
  deviceImei: "Device IMEI",
  deviceImeiSerial: "Device IMEI / Serial",
  deviceInfo: "Device Info",
  deviceLimitExceeded:
    "You are using {0} devices which exceeds your plan limit of {1} devices. Please contact the venue owner to upgrade your plan.",
  deviceLimitExceededManager:
    "You are using {0} devices which exceeds your plan limit of {1} devices. Please upgrade your plan.",
  deviceList: "Device List",
  deviceMac: "Device MAC Address",
  deviceMacAddress: "Device MAC Address",
  deviceName: "Device Name",
  deviceNameAlreadyExists: "This device name already exists",
  deviceNameUpdated: "Device “{0}” updated.",
  deviceSerialNumber: "Device Serial Number",
  deviceUid: "Device UID",
  devices: "Devices",
  dianError: "Dian Error",
  difference: "Difference",
  directory: "Directory",
  disableSellingOnPos: "Disable selling on POS",
  disabled: "Disabled",
  discard: "Discard",
  discardChanges: "Discard Changes",
  discardWristbandKeepingSettings: "Discard Wristband Keeping Settings",
  disclaimer: "Disclaimer",
  disclaimerCategoryReport:
    "DISCLAIMER: There may be products with multiple categories assigned to it which will result in double counting in each line item.",
  disclaimerDesc:
    "Please be advised that the use of older versions of our application is not recommended and may result in performance issues or security vulnerabilities. To ensure optimal performance and the highest level of security, we strongly recommend updating to the latest version of our app at your earliest convenience.\nThank you for your understanding and cooperation",
  disconnect: "Disconnect",
  discount: "Discount",
  discountAmount: "Discount Amount",
  discountApplied: "Discount Applied",
  discountCanBeApplied: "This discount can be applied in your venue.",
  discountCreated: 'Discount "{0}" created.',
  discountDeleted: 'Discount "{0}" deleted.',
  discountName: "Discount Name",
  discountNameAlreadyExist: "This discount name already exists.",
  discountRateOrValue: "Discount Rate or Value",
  discountReport: "Discount Report",
  discountType: "Discount Type",
  discountUpdated: 'Discount "{0}" updated.',
  discountableProducts: "Discountable Products",
  discounts: "Discounts",
  discountsPage: "Discounts Page",
  discrepancy: "Discrepancy",
  displayCategoryInPos: "Display this category in the POS devices.",
  displayedIn: "Displayed In",
  distribution: "Distribution",
  done: "Done",
  dontSellOnPos: "Don't Sell on POS",
  dontWorry: "Don’t worry!",
  download: "Download",
  downloadAPK: "Download APK",
  downloadAndSync: "Download PouchVENUE App and Sync your first transaction",
  downloadTemplate: "Download Template",
  downloading: "Downloading...",
  dropdown: "Dropdown",
  duplicateOption: "Duplicate option",
  eInvoice: "E-Invoice",
  eInvoiceSyncSuccessfully: "E-invoice has been synced successfully",
  eWallet: "E-wallet",
  easierGuestReg: "Easier Guest Registration and Check-ins",
  edit: "Edit",
  editAccessPoint: "Edit Access Point",
  editAccessToken: "Edit Access Token",
  editAccount: "Edit Account",
  editCategory: "Edit Category",
  editCharge: "Edit Charge",
  editDetails: "Edit Details",
  editDevice: "Edit Device",
  editDiscountsPage: "Edit Discount Page",
  editGuestProfile: "Edit Guest Profile",
  editLocation: "Edit Location",
  editLocations: "Edit Locations",
  editNewCategory: "Edit New Category",
  editNote: "Edit Note",
  editPaymentMethod: "Edit Payment Method",
  editPaymentMethods: "Edit Payment Methods",
  editPrinter: "Edit Printer Profile",
  editProduct: "Edit Product",
  editProductSet: "Edit Product Set",
  editQuestions: "Edit Questions",
  editSFTPDetails: "Edit SFTP Details",
  editStaff: "Edit Staff",
  editStaffPage: "Edit Staff Page",
  editStaffProfilePage: "Edit Staff Profile Page",
  editSupplyItem: "Edit Supply Item",
  editTax: "Edit Tax",
  editVariants: "Edit Variants",
  editVoucherPage: "Edit Voucher Page",
  editYourAccountPreferences: "Edit your account preferences, email address, and password.",
  egAppliesToGuests: "e.g. Applies to guests 60 yrs old and above",
  egSeniorDiscount: "e.g. Senior Discount",
  egSizeFlavor: "eg. Size, Flavor",
  eightCharactersMinimum: "8 characters minimum",
  eitherYouHavePoorConnection:
    "Either you have poor network connectivity, your access has been denied or something went wrong on our side.",
  email: "Email",
  emailAddress: "Email Address",
  emailAlreadyInVenue: "This email is already associated with an account in this venue.",
  emailDoesntHaveRecord:
    "The email you entered doesn’t match our record, please double check and try again.",
  enableDifferentiateEntryExit: "Enable Differentiate Entry/Exit",
  enableSellingOnPos: "Enable selling on POS",
  enableTip: "Enable Tip?",
  end: "End",
  endDay: "End Day",
  endOfDay: "End of Day",
  endOfDayChecklist: "End of Day Checklist",
  endOfDayCompleted: "End of Day Completed",
  endOfDayFailed: "Failed to Complete End of Day",
  endOfDayReports: "End of Day Reports",
  endOfDayReportsListPage: "End of Day Reports List Page",
  endOfDayRunning:
    "End of Day is Running. Please refresh the page to update the Time Ended status of EOD.",
  endedBy: "Ended by",
  enterEachOption: "Enter each option separated by a comma",
  enterEmailToLogin: "Enter your email address to sign up or to log in.",
  enterYourEmailAddress: "Enter your email address",
  enterYourPassword: "Enter your password",
  enteredPasswordIncorrect: "The entered password is incorrect.",
  equity: "Equity",
  error: "Error",
  errorLoadingColors: "Error loading colors. Please try again later.",
  eventMode: "Event Mode",
  example1: "Example 1",
  example1AssembleProduct:
    "{0}:  A Cafe Latte would need {1}, {2}, and {3}. The inventory would be deducted from the coffee beans, milk, and sugar when a Cafe Latte is purchased.",
  example2: "Example 2",
  example2AssembleProduct:
    "{0}: Wine can be sold as a {1} or a {2}. Select the wine as the supply item, then create the variants “bottle” and “glass” and adjust the quantity of the item per variant, so it would be deducted from the same supply item accoridingly.",
  expStockQty: "Exp. Stock Qty",
  expectedEndingBalance: "Expected Ending Balance",
  expectedQty: "Expected Qty",
  expense: "Expense",
  expired: "Expired",
  export: "Export",
  exportCheckinDetails: "Export Check-in Details",
  exportGuestReport: "Export Guest Report",
  exportList: "Export List",
  exportRegisterReport: "Export Shift Report",
  exportTaxReport: "Export Tax Report",
  exportTransactions: "Export Transactions",
  exportXlsx: "Export as XLSX",
  extendThePower: "Extend the power of your PouchVENUE with the best-in-class add-ons.",
  externalPaymentStatus: "External Payment Status",
  extremelyHighDenominations: "Extremely High Denominations",
  failed: "Failed",
  failedToLoad: "Failed to load.",
  featureLocked: "Feature Locked",
  feedback: "Feedback",
  feelFreeToContactPouchnation: "Please feel free to reach us at PouchNATION.",
  female: "Female",
  fileImported: "File imported",
  filters: "Filters",
  findSimilar: "Find Similar",
  firstName: "First Name",
  fixedAmount: "Fixed Amount",
  fixedAmountRange: "Fixed amount should be between 1-9,999,999",
  followTheOnScreenInstructionsToCompleteTheDownloadAndInstallation:
    "Follow the on-screen instructions to complete the download and installation.",
  footerSection: "Footer Section",
  forApproval: "For Approval",
  forDevicesWithoutTheGooglePlayStore: "For devices without the Google Play Store:",
  forProperReportingAndTo:
    "For proper reporting and to keep your dashboard up-to-date, please run the End of Day daily to avoid any data loss.",
  forProperReportingWithYourMews:
    "For proper reporting with your MEWS account, please make sure that your accounting editable history window is set to 7 days. Run the End of Day daily to avoid any data loss.",
  forceBanned: "Forced Checked-out and Ban Details",
  forceCheckOutBy: "Forced check-out by",
  forceCheckout: "Force Check-out",
  forceCheckoutDetails: "Forced Check-out Details",
  forceCheckoutName: "Force Check-out {0}?",
  forcedCheckedOutBy: "Forced Check-out by",
  formatting: "Formatting",
  freeCredits: "Free Credits",
  freeCreditsAreCreditsThatAreFreeOfCharge:
    "Free Credits are credits that are free of charge. These credits are",
  freeCreditsHoverTooltip:
    "Free credits are non-refundable and would be prioritized and used first upon payments.",
  freeCreditsIssued: "Free Credits Issued",
  freeCreditsKept: "Free Credits Kept",
  freeCreditsLiabilityAccount: "Free Credits Liability Account",
  freeCreditsLiabilityAccountMessage: "Cost of issued free credits to guests once used.",
  freeCreditsLiabilityAccountPlaceholder: "Liability/Free Credits",
  freeCreditsReceived: "Free Credits Received",
  freeCreditsRemoved: "Free Credits Removed",
  freeCreditsReport: "Free Credits Report",
  freeTrial: "30-day free trial!",
  from: "From",
  frontOffice: "Front Office",
  frontOfficeRoleAppDesc:
    "This role has no roles related to the App and would not be able to login on the device.",
  frontOfficeRoleDashboardDesc:
    "Allows access to Venue Dashboard’s Insights, Bookings, Guests, Transactions, Reports, Devices, and Ban Tags",
  future: "Future",
  gender: "Gender",
  general: "General",
  generalSettings: "General Settings",
  generateAndDownload: "Generate & Download",
  generateQRCode: "Generate QR Code",
  generateQRDescription:
    "Generate and print out QR codes for customers to scan and order from this location’s online menu.",
  generatingReport: "Generating Report...",
  getNotifications: "Get Notifications",
  getPouchVENUEeBook: "Get PouchVENUE eBook",
  getStarted: "Get started",
  glass: "glass",
  glossary: "Glossary",
  goToThe: "Go to the ",
  goToTheSiigoApiKey: "screen from your Siigo Dashboard to find your API Key.",
  goToVenueTabAndTapOnPushVenueData: "Go to “Venue” tab and tap on Push Venue Data",
  goToXDashboard: "Go to {0} Dashboard",
  grossProfit: "Gross Profit",
  guest: "Guest",
  guestCheckedOutAndBanned: "Guest checked-out and tag banned.",
  guestDetails: "Guest Details",
  guestId: "Guest ID",
  guestInfoNotYetSync: "Guest info not synced yet",
  guestInformationYouCollect: "Guest information you collect upon check-in.",
  guestList: "Guest List",
  guestName: "Guest Name",
  guestNameAndOrTagId: "Guest Name and/or Tag ID",
  guestOrTagStatus: "Guest or Tag Status",
  guestProfile: "Guest Profile",
  guestProfileAmountPlaceholder: "0.00",
  guestProfileDeleted: "Guest profile deleted.",
  guestProfileDescriptionPlaceholder: "e.g. “Guests who checks-in the foodcourt“",
  guestProfileList: "Guest Profile List",
  guestProfileName: "Guest Profile Name",
  guestProfileNameCreate: "Guest profile “{0}” created.",
  guestProfileNamePlaceholder: "e.g. “Food Court Guests“",
  guestProfiles: "Guest Profiles",
  guestQuestions: "Guest Questions",
  guestSince: "Guest since {0}",
  guestSpecialRequest: "Guest Special Requests",
  guestTap: "Guest Tap",
  guestWithColonSymbol: "Guest:",
  guests: "Guests",
  guestsCheckedIn: "Guests Checked In",
  guestsNewBalance: "Guest's New Balance",
  guestsPreviousBalance: "Guest's Previous Balance",
  guestsPreviousBalanceTotalPaidAndNewBalance:
    "Guests Previous Balance, Total Paid, and New Balance",
  guestsStatus: "Guests Status",
  guidelineDownloadOrUpdateApp: "Guideline on How to Download or Update the App",
  headOfBooking: "Head of Booking",
  headerSection: "Header Section",
  hello: "Hello",
  helpCenter: "Help Center",
  here: "here",
  hiHeresWhatsHappening: "Hi {0}, here's what's happening with {1}.",
  hidden: "Hidden",
  hide: "Hide",
  hideDetails: "Hide Details",
  hideInPos: "Hide in POS",
  hideOnlineMenu: "Hide in Online Menu",
  hideVariant: "Hide Variant",
  highDenominations: "High Denominations",
  holdingAccountForToppedUpItems:
    "The holding account for all credits that are topped up in a tag before sales",
  hostname: "Hostname",
  hour: "hour",
  hourly: "Hourly",
  hours: "hours",
  id: "ID",
  idType: "ID Type",
  ifYouHaveAPreviousVersionOfApp:
    "If you have a previous version of the App, please delete the app before updating",
  ifYouLeaveThisPage:
    "If you leave this page, all unsaved changes will be lost. Are you sure you want to discard changes?",
  ifYoudLikeToJustStopSellingProduct:
    "If you’d like to just stop selling a product on your POS, simply disable it by turning off “Sell on POS”.",
  ignore: "Ignore",
  ignoreAllOpenShifts: "Ignore All Open Shifts",
  ignoreAllShifts: "Ignore All Shifts",
  ignoredShiftFromPrevious: "Ignored shift from previous EOD",
  imageTypeSize: "JPG, GIF or PNG. Max size of 1MB",
  impItem: "Item Import",
  impItemMaxSize: "Max size 25MB",
  impItemModalNote1:
    "For Supply Item/Product already existed in inventory, import shall increase quantity of that supply item in inventory",
  impItemModalNote2:
    "For Supply Item/Product not existed in inventory, click confirm shall generate new SKU in inventory",
  impItemsErr1: "Location {0} cannot be found",
  impItemsErr2: "Measurement {0} not found",
  impItemsErr3: "Sheet {0} {1} data format incorrect",
  impItemsErr4: "There is a difference in price for supply item {0} + Measurement {1}",
  impItemsErr5: "Sheet {0} should not exceed 200 rows",
  impItemsErr6: "Category not found",
  impItemsErr7: "There is a difference in price for Product {0} + Measurement {1}",
  impItemsPlsRecheck: "Please recheck data in file import and upload again",
  import: "Import",
  importSupplyItems: "Import Supply Items",
  importSupplyItemsCreateWholeProducts: "Import Supply Items and Create Whole Products",
  importSupplyItemsErr:
    "Location names are not matched with existing locations in the venue. Please recheck",
  in: "in",
  inHouse: "In-House",
  inProgress: "In Progress",
  inStock: "In Stock",
  inactive: "Inactive",
  include: "Include",
  indeterminate: "indeterminate",
  information: "Information",
  informationToCollect: "Information to collect upon guest registration.",
  inputReason: "Input Reason",
  installAPK: "Install APK",
  integrateDesc: "Integrate with powerful property managements systems and more.",
  integration: "Integration",
  integrationSettingDesc:
    "Connect with other business and productivity apps to help you grow and streamline your business.",
  integrationSettings: "Integration Settings",
  integrations: "Integrations",
  interval: "Interval",
  invalidFileType: "Invalid file type. Allowed file types are JPG or PNG.",
  invalidOptions: "Invalid option(s).",
  invalidTimeRange: "Invalid time range",
  invalidToken: "Invalid Token",
  inventory: "Inventory",
  inventoryAccount: "Inventory Account",
  inventoryAccountMessage: "For purchased supply items and inventory before selling.",
  inventoryAccountPlaceholder: "Asset/Inventory",
  inventoryExisted: "Inventory Existed",
  inventoryGainLossAccount: "Inventory Gain/Loss Account",
  inventoryGainLossAccountMessage: "For discrepancies in stocktake, stock adjustments, etc.",
  inventoryGainLossAccountPlaceholder: "Gain/Loss on Sales of Inventory",
  inventoryGroup: "Inventory Group",
  inventoryManagement: "Inventory Management",
  inventoryMovement: "Inventory Movement",
  inventoryMovementPage: "Inventory Movement Page",
  inventoryReport: "Inventory Report",
  inventoryValue: "Inventory Value",
  invitationHasBeenSentTo: "Invitation has been sent to {0}.",
  inviteNewStaff: "Invite New Staff",
  inviteResponse: "Invite Response",
  inviteYourStaff: "Invite Your Staff",
  inviteYourVenueStaff: "Invite your venue’s staff, manage their role and access in your POS",
  invoice: "Invoice",
  invoiceDate: "Invoice Date",
  invoiceNumber: "Invoice No",
  invoiceType: "Invoice Type",
  invoiced: "Invoiced",
  invoicedInSiigo: "Invoiced in Siigo",
  invoices: "Invoices",
  isAreserveWord: "is a reserve word.",
  issuanceLimit: "Issuance Limit",
  issue: "Issue",
  issueDate: "Issue Date",
  issueFreeCredits: "Issue Free Credits",
  issuedBy: "Issued by",
  issuedOn: "Issued on",
  item: "Item",
  itemBargain: "This item’s price has been changed from {0} to {1}",
  itemImport: "Item Import",
  itemName: "Item Name",
  itemTax: "Item Tax",
  items: "Items",
  itemsSold: "Items Sold",
  justNow: "Just now",
  lastIssuedTo: "Last Issued To",
  lastModifiedBy: "Last Modified by",
  lastModifiedOn: "Last Modified on",
  lastName: "Last Name",
  lastPurchasedProduct: "Last Purchased Product",
  lastSync: "Last Sync",
  lastSynced: "Last synced {0}",
  lastVisitedLocation: "Last Visited Location",
  learnHowToRenewSubscription: "Learn how to renew your subscription",
  learnMore: "Learn More",
  legalBusinessName: "Legal Business Name",
  letsGetStarted: "Let's get started",
  liability: "Liability",
  limit: "Limit",
  limitVoucherPerPerson: "Limit voucher issued per person",
  linkAccountingItem: "Link Accounting Item",
  linkOutlet: "Link Outlet",
  linkOutletHoverTooltip: "Link to MEWS Outlets",
  linkWithMewsAccountingItems: "Link with MEWS Accounting Items",
  litePlanMaxProductReached:
    "You’ve reached the maximum number of {0} products with your {1} plan.",
  litePlanMaxStaffReached: "You’ve reached the maximum number of {0} staff with your {1} plan.",
  loading: "Loading...",
  locateAPK: "Locate APK",
  locateAPKDesc: 'Open your device’s file manager and find the APK in the "Downloads" folder.',
  location: "Location",
  locationDesignation: "Location Designation",
  locationName: "Location Name",
  locationNameAlreadyExist: "This location already exists. Choose a different name.",
  locationNameCreated: "Location “{0}” created.",
  locationNameDeleted: "Location “{0}” deleted.",
  locationNameUpdated: "Location “{0}” updated.",
  locationQRCode: "Location QR Code (One for the whole location)",
  locationVoucherRedeemed: "Choose location(s) where this voucher is redeemed.",
  locations: "Locations",
  locationsList: "Locations List",
  logIn: "Log in",
  loginHasEnded: "Your login session has already ended. Please login again.",
  loginSessionEnded: "Login Session Ended",
  logout: "Logout",
  looksLikeAlreadyInSystem: "Looks like you’re already in our system!",
  looksLikeYouEncounteredSomething:
    "Looks like you encountered an error, we track these errors automatically. For the mean time, try refreshing.",
  looksLikeYouHaveNoLocationYet: "Looks like you have no locations yet.",
  lostTag: "Lost Tag",
  lostTags: "Lost Tags",
  lowDenominations: "Low Denominations",
  lowOrOutOfStock: "Low or Out of Stock",
  lowParLevel: "Low PAR Level",
  lowerCaseNoneRefundable: "non-refundable",
  lowerCaseTopUpCredits: "top-up credits",
  mailingList: "Mailing List",
  male: "Male",
  manageAccountNotificationStockLevel:
    "Manage accounts to notifiy when an item falls to its alert level.",
  manageAndAssignQuestions:
    "Manage and assign check-in questions, free credits and vouchers to different guest profiles.",
  manageBusinessInfo: "Manage Business Information",
  manageEmailNotif: "Manage emails notification sent to you and your staff.",
  manageHowVenueTax:
    "Manage how your venue charges taxes depending on your region. Consult with a tax expert to understand your tax obligations.",
  manageImportantDetailsSettings:
    "Manage the important details and rules of your venue such as venue settings, payment and credit settings, check-in settings and your account settings.",
  manageInventory: "Manage Inventory",
  manageInventoryAndProducts: "Manage the inventory and products of this set.",
  manageInventoryAndSupply: "Manage the inventory and supply items of this product.",
  manageLocationTable: "Manage this location’s table and seating arrangements.",
  manageNotifcations: "Manage email alerts and notifications sent to your staff.",
  manageOfPermissionToEnterSpecificAreas: "Manage of permission to enter specific areas",
  managePlacesYouStock: "Manage places you stock inventory and sell products.",
  manageReceipts: "Manage the appearance and information of your receipts.",
  manageSupplyItems: "Manage Supply Items",
  manageThePosDevicesVenue: "Manage POS devices that are logged in and is being used in your venue",
  manageTheStocksPerLocation: "Manage the stocks per location of this supply item.",
  manageTipDesc: "Enable tip and set tip percentage you would like to display on POS",
  manageToppedUpCredits:
    "Manage topped-up credits, free credits, postpaid credits, and expired credits.",
  manageVariants: "Manage Variants",
  manageVenues: "Manage Venues",
  manageYourAccountsSales:
    "Manage your accounts regarding sales, cost of goods, product taxes, or discounts.",
  manageYourStaffProfileDescription:
    "Manage your staff, their roles, designations, and staff profile they belong to.",
  manageYourVenueSetting: "Manage your venue settings such as taxes, and currency.",
  manageYourVenueSubscription:
    "Manage your venue's subscription, billing information, and invoices.",
  manageYourVenueTax: "Add and manage how your venue charges taxes.",
  manager: "Manager",
  managerAuthorization: "Manager Authorization",
  managerRoleDesc: "Full access to both online Venue Dashboard and Venue App.",
  manuallyDownloadUpdatePouchApp:
    "Manually Download/Update PouchNATION App from the Google Play Store:",
  mapAndManageAccounts:
    "Map and manage your default accounts for each PouchVENUE business process.",
  mapAndManageAccountsLink: "Map and manage your accounts in {0} for proper accounting",
  mapToSiigo: "Map to Siigo",
  mapYourAccounts: "Map your accounts for inventory assets, discrepancy expenses, etc.",
  margin: "Margin",
  markUp: "Markup",
  maxProductReached: "Maximum Product Reached",
  maxStaffReached: "Maximum Staff Reached",
  maximumLengthCharacters: "Maximum {0} character(s).",
  maximumValueAllowed: "Maximum value allowed is 20,000,000",
  maximumValueIs: "Maximum value is",
  measurement: "Measurement",
  measurements: "Measurements",
  mediumDenominations: "Medium Denominations",
  menuEngineering: "Menu Engineering",
  mews: "MEWS",
  mewsAccounting: "MEWS_Accounting",
  mewsAccounting1: "MEWS Accounting",
  mewsBookings: "MEWS_Bookings",
  mewsBookings1: "MEWS Bookings",
  mewsSystems: "MEWS Systems",
  mewsTaxCode: "Is the tax code from MEWS?",
  mewsTaxCodeEditNote:
    "Note: When transactions are synced, products linked to this tax will have VAT amount in MEWS.",
  milk: "milk",
  minOfNCharacters: "Minimum of {0} characters",
  minimum: "Minimum",
  minimumCharactersAllowed: "Minimum of {0} characters allowed",
  minimumOf: "Minimum of 2 items required.",
  minimumValueIs: "Minimum value is",
  minute: "minute",
  minutes: "minutes",
  missedCheckIn: "Missed Check-in",
  missedCheckOut: "Missed Check-Out",
  missingTransactionsDetected: "Missing transactions detected",
  mixed: "Mixed",
  mobileNumber: "Mobile Number",
  mode: "Mode",
  month: "month",
  monthly: "Monthly",
  months: "months",
  monthsAfterCheckout: "months after check-out",
  mostPurchasedProduct: "Most Purchased Product",
  mostVisitedLocation: "Most Visited Location",
  movement: "Movement",
  multiProperty: "Multi Property",
  nOptions: "{0} options",
  nTon: "{0} to {1}",
  na: "N/A",
  name: "Name",
  nameAdded: "“{0}” added.",
  nameYourProduct: "Name your product...",
  nameYourProductSet: "Name your product set...",
  nameYourSupplyItem: "Name your supply item",
  nationality: "Nationality",
  needHelp: "Need Help?",
  netWeightN: "Net Weight {0}",
  new: "New",
  newBalance: "New Balance",
  newGuests: "New Guests",
  newInviteLink: 'New invite link has been sent to "{0}".',
  newPassWord: "New Password",
  newProduct: "New Product",
  newProductSet: "New Product Set",
  newQty: "New Qty",
  newQuantity: "New Quantity",
  newSku: "New SKU",
  newToPouchNation: "New to PouchNATION?",
  nfcStatus: "NFC Status",
  nfcTags: "NFC tags",
  night: "night",
  no: "No",
  noAccountsAvailable: "No Account(s) Available",
  noAccountsYet: "No Accounts Yet",
  noAvailableAccessPoint: "No available Access Point",
  noAvailableCategories: "No Available Categories",
  noAvailableGuestProfile: "No available Guest Profile",
  noAvailableLocations: "No available locations",
  noAvailableLocationsYet: "No available locations yet.",
  noAvailablePaymentMethodYet: "No available payment method yet.",
  noAvailableStaff: "No Available Staff",
  noBannedTagsYet: "No banned tags yet.",
  noBookingsYet: "No bookings yet.",
  noCheckInsInDateRange: "There were no check-ins in this date range.",
  noData: "No Data",
  noDataAvailable: "No data available",
  noDeviceAvailable: "No Device Available",
  noDevicesFound: "No devices found",
  noEndDate: "No end date",
  noLocation: "No Location",
  noLocationAvailable: "No Location Available",
  noNotesFound: "No Notes Found",
  noOfCheckedIn: "{0} checked-in",
  noOfCheckedOut: "{0} checked-out",
  noOfGuests: "No. of Guests",
  noOfNights: "No. of Nights",
  noOfTables: "# of Tables",
  noProductsAvailable: "No products available",
  noProductsSelected: "No products selected yet.",
  noRecords: "No records.",
  noRecordsOf: "No records of",
  noRemainingBalance: "No Remaining Balance",
  noReportsFound: "No reports found.",
  noResultInSearch: "We did not find any results for your search.",
  noResults: "No Results",
  noResultsFound: "No Results Found",
  noResultsMatching: "No results matching",
  noResultsMatchingX: "No results matching “{0}”",
  noReturnCreditsInDateRange: "There were no return credits in this date range.",
  noSalesInDateRange: "There were no sales in this date range.",
  noSearchFound: "No Search Found",
  noSearchResultsForName: "No search results for {0}.",
  noShow: "No Show",
  noStocks: "No Stocks",
  noTagUid: "No Tag UID",
  noTagYet: "No tag yet",
  noTagsFound: "No tags found.",
  noVoucherAvailable: "No Voucher Available",
  noVoucherMovement: "No voucher movement.",
  noVouchersSelectedYet: "No vouchers selected yet.",
  nonRefundable: "Non-Refundable",
  none: "None",
  notCheckedIn: "Not Checked-in",
  notSellingOnPos: "Not Selling on POS",
  note: "note",
  notePreviousTransaction: "Note: Your previous transactions with these products will be retained.",
  noteTransactionStaff: "Note: Your previous transactions with this staff will be retained.",
  noteUpdateLastVersion:
    "Note: This “Pop Up” will keep showing until all your active devices are updated to the latest version. Check the app version of your devices, ",
  notedByOn: "Noted by {0} on {1}",
  notes: "Notes",
  notesAndGuideline: "Notes and Guideline",
  notificationSettings: "Notification Settings",
  notifications: "Notifications",
  nowToDo: "now to do ",
  nthGuestWhoUseThisTag: "{0} guest who used this tag.",
  nthStaffWhoUsedThisTag: "{0} staff who used this tag.",
  nthUse: "{0} use",
  numberOfGuests: "# of Guests",
  numberOfNights: "# of Nights",
  offline: "Offline.",
  ok: "Ok",
  okay: "Okay",
  oldGuestId: "Old Guest ID",
  onPos: "On POS",
  onboardingDescription: "Setup your venue with these simple steps to get your venue started.",
  onboardingDescriptionCompleted:
    "Awesome! You can now start selling on your PouchVENUE POS devices! Order your NFC tags now to do cashless transactions with door lock activation! Check out our ebook as well on how to optimize your venue!",
  onboardingTitle: "Welcome! Start setting up your venue.",
  onboardingTitleCompleted: "Your venue is ready to go!",
  onceYouBanATag: "Once you ban a tag, that tag cannot be used to transact inside your venue.",
  onceYouCompleteStocktake:
    "Once you complete a stocktake, all actual quantities of the supply items would be adjusted accordingly. This action cannot be undone.",
  onceYoureOnTheAppsPage: "Once you're on the app's page:",
  onceYoureOnTheAppsPageDesc:
    "Click the “Install”/“Update” button\n(For updating the app, the Google Play Store will automatically check for updates and prompt you to update if necessary.)",
  onlineMenuOnly: "Online Menu Only",
  onlyDateRangesLessThanOneYear: "Only date ranges less than one year are accepted",
  open: "Open",
  openReceiptPreview: "Open Receipt Preview",
  openWindow: "Open Window To Locations",
  opened: "Opened",
  openingBalance: "Opening Balance",
  optionNameAlreadyExists: "Option name already exists",
  options: "Options",
  orNo: "OR#",
  orderQty: "Order Qty",
  orderQuantityRequired: "Order quantity is required",
  orderYour: "Order your",
  orderYourNFCTags: "Order Your NFC Tags",
  original: "Original",
  other: "Other",
  others: "Others",
  outlet: "outlet",
  overview: "Overview",
  pairedTagOnCheckin: "Paired Tag on Check-in",
  pairedTags: "Paired Tags",
  parLevel: "Par Level",
  password: "Password",
  passwordDoesNotMatch: "The passwords you entered do not match",
  passwordIncorrect: "The password you entered is incorrect.",
  passwordsDoNotMatch: "Passwords do not match",
  pasteUrlInDeviceBrowserAndDownload:
    "Paste the URL in your device’s browser and download the APK or follow the link from the QR code to download the APK.",
  paymentAndCreditSettings: "Payment and Credit Settings",
  paymentMethod: "Payment Method",
  paymentMethodAddedName: "Payment Method “{0}“ added.",
  paymentMethodAlreadyExist: "Payment method already exists",
  paymentMethodInSiigo: "Payment Method in Siigo",
  paymentMethodName: "Payment Method Name",
  paymentMethods: "Payment Methods",
  paymentMethodsDescription: "Create and manage payment methods you accept in your venue.",
  paymentMethodsSettings: "Payment Methods Settings",
  paymentMethodsYouAccept: "Payment methods you accept in your venue.",
  paymentStatus: "Payment Status",
  paymentTerms: "Payment Terms:",
  pending: "Pending",
  perPage: "{0} per page",
  percentage: "Percentage",
  percentageBetween: "Percentage should be between 1-100",
  permitNumberWithColonSymbol: "Permit Number:",
  phone: "Phone",
  phoneNo: "Contact No.",
  pleaseCheckYourNetwork: "Please check your network to update your venue data.",
  pleaseChooseLocation: "Please Choose location",
  pleaseConnectToAnAccounting:
    "Please connect to an accounting integration to manage your Chart of Accounts.",
  pleaseContactOwnerToReactiveVenue:
    "Please contact the venue owner to renew the subscription and reactivate this venue.",
  pleaseContactSupportRenewSubscription:
    "Please contact support to renew your subscription and reactivate your venue.",
  pleaseCopyUrl: "Please copy the URL below and paste in your browser or scan the QR code:",
  pleaseCopyUrlApk:
    "If your device does not have the Google Play Store, please download the app from URL below or scan the QR code with the device: ",
  pleaseCreateAccounts: "Please create account codes from your integrated accounting platform.",
  pleaseCreateLocation: "Please create at least 1 location to allocate this supply item's stocks.",
  pleaseEmailUs: "Please email us at {0} {1}",
  pleaseEnsureLocation:
    "Please ensure that a location has no ongoing transactions before starting a stocktake.",
  pleaseEnterYourSFTP: "Please enter your SFTP server details and credentials.",
  pleaseFetchDataFromSiigoByClickingButtonBelow:
    "Please fetch data from Siigo by clicking button below",
  pleaseMakeSureToSyncAllDataBeforeDeleteApp:
    "Please make sure to sync all data before delete the app.",
  pleaseReadThisInstructionCarefully: "Please read this instruction carefully",
  pleaseRenewSubscription: "Please renew your subscription to reactivate your venue.",
  pleaseSelectLocation: "Please select a location.",
  pleaseSelectStockUnit: "Please Select Stock Unit",
  pleaseUpgradeToUseLockedFeature: "Please upgrade your plan to use this locked feature.",
  pointOfSale: "Point-of-Sale",
  posAndOnlineMenu: "POS + Online Menu",
  posDevices: "Point-of-Sale Devices",
  posOnly: "POS Only",
  posProvider: "POS Provider",
  postPaidCreditsIsTheAmount:
    "Postpaid credits is the amount a guest could use when their balance reaches zero. When a guest has postpaid credits balance, the amount should be settled and paid for via top-up oor upon checkout",
  postPaidCreditsUsed: "Postpaid Credits Used",
  postpaidCreditLimit: "Postpaid Credit Limit",
  postpaidCredits: "Postpaid Credits",
  postpaidCreditsLiabilityAccount: "Postpaid Credits Liability Account",
  postpaidCreditsLiabilityAccountMessage:
    "Credits used when a guest reaches a zero balance. To be paid via top-up upon check-out.",
  postpaidCreditsLiabilityAccountPlaceholder: "Asset/Postpaid Credits",
  postpaidCreditsUsed: "Postpaid Credits Used",
  postpaidCreditsUsedCanOnlyBeReplenished:
    "Postpaid Credits Used can only be replenished and paid for via top-up or upon check-out.",
  pouchConnect: "PouchCONNECT",
  pouchLogo: "PouchNATION Logo",
  pouchPay: "PouchPAY",
  pouchnation: "PouchNATION",
  pouchnationPaymentMethod: "PouchNATION Payment Method",
  pouchnationTax: "PouchNATION Tax",
  powerYourVenue: "Power Your Venue with Integrations.",
  preloadedCredit: "Pre-loaded credit",
  preloadedVouchers: "Preloaded Vouchers",
  prepareImport: "Preparing Import",
  preparingReport: "Preparing Report",
  previousBalance: "Previous Balance",
  price: "Price",
  pricePerOrderQty: "Price per Order Qty",
  pricing: "Pricing",
  pricingAndTaxes: "Pricing and Taxes",
  printableItems: "Printable Items",
  printableItemsSelect: "Choose which products are printed on this printer profile.",
  printerLocationsDesc: "Choose location(s) where this printer profile can be used.",
  printerProfile: "Printer Profile",
  printerProfileCreated: "Printer Profile “{0}” created.",
  printerProfileDeleted: "Printer Profile Deleted",
  printerProfileName: "Printer Profile Name",
  printerProfileNameAlreadyExists: "This printer profile name already exists.",
  printerProfileUpdated: "Printer Profile “{0}” updated.",
  printerProfiles: "Printer Profiles",
  printers: "Printers",
  prioritizedAndUsedFirst: "prioritized and used first upon payments.",
  privacy: "Privacy",
  privacyPolicy: "privacy policy",
  privacyPolicy2: "Privacy Policy",
  proceed: "Proceed",
  proceedToCheckout: "Proceed to Checkout",
  processingTransactions: "Processing Transactions...",
  product: "Product",
  productCategories: "Product Categories",
  productCategory: "Product Category",
  productCodeInSiigo: "Product code in Siigo",
  productCount: "Product Count",
  productDeleted: 'Product "{0}" deleted.',
  productForTips: "Product for tips",
  productList: "Product List",
  productName: "Product Name",
  productRedeemed: "Product Redeemed",
  productRemovedFromInventory: "{0} product removed from inventory.",
  productSet: "Product Set",
  productSetDeleted: 'Product set "{0}" deleted.',
  productSetName: "Product Set Name",
  productSettings: "Product Settings",
  productUpdated: "{0} product updated.",
  products: "Products",
  productsArePartOfComposite:
    "These products are part of other composite products. To delete, remove them first from the composite products or delete them {0} with the composite products that carry them.  ",
  productsAreSupplyItemsAre:
    "{0} are the items that you sell in your venue. {1} are the items that you use to build your products. Supply items are managed in your {2}.",
  productsCanBeRedeemed: "Choose which products can be redeemed from this voucher.",
  productsDeleted: "{0} products deleted.",
  productsDoesNotExist: "Products you wanted to add must exist in all locations.",
  productsForDeletion:
    "{0} product(s) with this location are also up for deletion. This cannot be undone.",
  productsRemovedFromInventory: "{0} products removed from inventory.",
  productsToBeDeleted: "Products to be deleted",
  productsUpdated: "{0} products updated.",
  productsVSupplyItems: "Products vs Supply Items",
  productsVariants: "Products / Variants",
  productsWithNoCategory: "These are products with no categories yet",
  profile: "Profile",
  profileName: "Profile Name",
  profileNameAlreadyExists: "Profile name already exists, please enter a different name.",
  propertyManagementSystem: "Property Management System",
  propertyManagementSystemExample: "Systems like MEWS and Cloudbeds",
  purchasePlus: "PurchasePlus",
  purchaseplus: "Purchase Plus",
  purchasingAndInventory: "Purchasing and Inventory",
  qrCodeForOnlineMenu: "QR Code for Online Menu",
  qty: "Qty",
  quantity: "Quantity",
  quantitySupplyItems: "The quantity of supply items used for this product.",
  quarter: "Quarter",
  quarterly: "Quarterly",
  question: "Question",
  questionAddedName: "Question “{0}“ added.",
  questionAlreadyExist: "Question already exists.",
  questionDeleted: "Question “{0}“ deleted.",
  questionDescription:
    "Information to collect upon guest registration. Use these questions by assigning it to your {0}.",
  questionSettings: "Question Settings",
  questions: "Questions",
  radioButton: "Radio Button",
  reachedReorderPoint: "Reached Reorder Point",
  readyForDownload: "ready for download",
  reallyUnbanTag: "Really unban this tag?",
  reallyUnbanThisTagDescription:
    "Unbanning this tag will only take effect next time your devices are synced and updated (usually every 5 minutes when connected to internet).  To reflect these changes immediately, please sync and update your devices once you unban this tag.",
  reason: "Reason",
  reasonsForStay: "Reasons for Stay",
  receiptFormatting: "Receipt Formatting",
  receiptNumber: "Receipt Number",
  receiptSettings: "Receipt Settings",
  receipts: "Receipts",
  recentTransactions: "Recent Transactions",
  reconnect: "Reconnect",
  redeem: "Redeem",
  redeemableProducts: "Redeemable Products",
  redeemed: "Redeemed",
  referenceNo: "Ref. No.",
  refreshingThisPage: "Refreshing this page",
  refundedCredits: "Refunded Credits",
  registerGuests: "Register Guests",
  registerGuestsRoleAppDesc: "This role does not have access to the online Venue Dashboard.",
  registerGuestsRoleDashboardDesc: "Allows access to Venue App’s Check-in and Check-out guests.",
  registerInfo: "Register Info",
  registerReports: "Shift Reports",
  registerReportsPage: "Shift Reports Page",
  registerStaff: "Register Staff",
  registeredBusinessInformation: "Registered Business Information",
  registeredBusinessTaxNumber: "Registered Business Tax Number",
  registeredOfficialBusinessName: "Registered/Official Business Name",
  regulateEntryExit: "Regulate Entry/Exit",
  regulateEntryExitDesc:
    "Activating this feature allows precise regulation of entry and exit at the access point.\nWhen deactivated, access control remains functional, but it will not differentiate between entries and exits.",
  remove: "Remove",
  removeFreeCredits: "Remove Free Credits",
  removeFromList: "Remove from list",
  removeNameAsYourStaff: "Remove {0} as your staff?",
  removeSku: "Removed SKU",
  removeStaff: "Remove staff?",
  removeStocks: "Remove Stocks?",
  removeStocksConfirm: "Remove Stocks",
  removeStocksDetails: "Are you sure you want to remove {0} {1} in stocks of {2} in {3}?",
  removeThisAccount: "Remove this account?",
  removeThisSupplyItem: "Remove this supply item first from the products to delete this item.",
  removeVariant: "Remove Variant",
  removeVariantFirstFromProduct: "Remove this variant first from the product",
  removedStock: "Removed Stock",
  renewSubscription: "Renew Subscription",
  reorderPoint: "Reorder Point",
  reorderPointReached: "Reorder Point Reached",
  reorderStocks: "Reorder Stocks",
  report: "Report",
  reportAProblem: "Report a Problem",
  reportingDay: "Reporting Day",
  reports: "Reports",
  reportsFailedToLoad: "Reports failed to load.",
  reportsInfoPage: "Reports Info Page",
  requireManagerAuthorization: "Require manager authorization when claiming this discount.",
  requireManagerAuthorizationVoucher: "Require manager authorisation when issuing this voucher.",
  required: "Required",
  resendInvite: "Resend Invite?",
  resetExpiration: "Reset Expiration",
  resetTheExpirationPeriod:
    "Reset the expiration period once a guest completes a transaction without checking in.",
  restrictUseOfTags: "Restrict use of certain tags in your venue.",
  resync: "Re-sync",
  resyncEInvoice: "Re-sync E-Invoice",
  resyncSuccessfully: "Re-sync successfully",
  retailPrice: "Retail Price",
  retailPriceMustNotBeNegative: "Retail price must not be negative",
  retryReloadingChart: "Retry Loading Chart",
  returnCredits: "Return Credits",
  returnCreditsRoleAppDesc: "This role does not have access to the online Venue Dashboard.",
  returnCreditsRoleDashboardDesc: "Allows access to Venue App’s Return Credits",
  returnedTags: "Returned Tags",
  returnedVoucher: "Returned Voucher",
  returningGuests: "Returning Guests",
  revenue: "Revenue",
  revenueAccount: "Revenue Account",
  revenueAccountMessage: "The default account for all sale revenue.",
  revenueAccountPlaceholder: "Revenue/Sales",
  reviewYourDailyVenueActivity: "Review your daily venue activity. ",
  revokeInvite: "Revoke Invite",
  revokeThisInvite: "Revoke this invite?",
  roadMap: "Roadmap",
  role: "Role",
  roleAndDesignation: "Role and Designation",
  roles: "Roles",
  rooms: "Rooms",
  runEndOfDay: "Run End of Day",
  running: "Running",
  runningTheEodWithUnsynced:
    "Running the EOD with unsynced transactions will result to an incomplete report.",
  sale: "Sale",
  saleDiscounts: "Revenue/Sales Discounts",
  saleFoc: "Revenue/ Sales FOC",
  saleReturn: "Sale Return",
  sales: "Sales",
  salesDiscounts: "Sales Discounts",
  salesDiscountsMessage: "The cost for all applied discounts.",
  salesDiscountsPlaceholder: "Sales Discount",
  salesFOC: "Sales FOC",
  salesFOCMessage: "The cost for all applied free credits.",
  salesFOCPlaceholder: "Revenue/Sales FOC",
  salesInformation: "Sales Information",
  salesOverTime: "Sales Over Time",
  salesReport: "Sales Report",
  salesSummary: "Sales Summary",
  salesTax: "Sales Tax",
  save: "Save",
  saveAccountSetting: "Save Account Setting",
  saveCategoryForm: "Save Category Form",
  saveCreditSetting: "Save Credit Setting",
  saveDeviceForm: "Save Device Form",
  saveDiscountForm: "Save Discount Form",
  saveGeneralSettings: "Save General Settings",
  saveLocationForm: "Save Location Form",
  saveNewSupplyItemForm: "Save New Supply Item Form",
  savePaymentMethod: "Save Payment Method",
  savePrinterProfileForm: "Save Printer Profile Form",
  saveProduct: "Save Product",
  saveProductSet: "Save Product Set",
  saveQuestion: "Save Question",
  saveReceiptSettings: "Save Receipt Settings",
  saveStaffForm: "Save prm",
  saveStaffProfileForm: "Save Staff Profile Form",
  saveTaxSetting: "Save Tax Setting",
  saveVenueForm: "Save Venue Form",
  saveVoucherForm: "Save Voucher Form",
  saveWristbandKeepingSettings: "Save Wristband Keeping Settings",
  savedChanges: "Saved Changes",
  scanQRcode: "Scan QR code",
  scanningTheQRcode: "Scanning the QR code",
  scanningTheQRcodeDesc:
    " Open your device's camera app and point your camera at the provided QR code. This will automatically open a link to the app's page on the Google Play Store.",
  scanningTheQRcodeOrUsingUrl: "Scanning the QR Code or Using the URL",
  scheduleACall: "Schedule a Call",
  search: "Search",
  search2: "Search...",
  searchBanTags: "Search banned tags by Tag UID",
  searchCategories: "Search and select categories for this voucher",
  searchEInvoicePlaceholder: "Search e-invoice by Siigo Inv No., Guest Name or CUEF Number",
  searchForProducts: "Search for products or supply items",
  searchForStockId: "Search for Stock ID...",
  searchForStocktakeId: "Search for Stocktake ID...",
  searchGuestsBalanceMovements: "Search guests by name or tag ID",
  searchMovementPlaceholder: "Search movements by voucher name, device, guest",
  searchProductByKeyOrSku: "Search Products by keyword or SKU",
  searchProductsByKeyword: "Search Products by keyword",
  searchProductsIn: "Search products in {0}",
  searchReportsByShiftId: "Search reports by shift id",
  searchResult: "search result",
  searchResults: "search results",
  searchStaffEmailOrTagID: "Search Staff email or tag ID",
  searchSupplyItemBy: "Search supply item by name or SKU",
  searchTag: "Search Tag",
  searchTransactionPlaceholder: "Search transactions by transaction ID, tag ID, Check-in ID",
  searchVouchers: "Search Vouchers",
  second: "second",
  seconds: "seconds",
  seeError: "See error",
  selectAType: "Select a type",
  selectAccessPoints: "Select Access Points",
  selectAccount: "Select Account",
  selectAccountType: "Select Account Type",
  selectAndMakeComposition: "Select the make and composition of this product.",
  selectCategoriesOrProducts: "Select categories or products from your chosen locations",
  selectCategory: "Select Category",
  selectCharge: "Select Charge",
  selectCountry: "Select Country",
  selectDevice: "Select Device",
  selectGuestProfile: "Select Guest Profile",
  selectInventoryGroup: "Select Inventory Group",
  selectInventoryGroupFormSiigoToAssign:
    "Select an inventory group from Siigo to assign to this product",
  selectInvoiceType: "Select Invoice Type",
  selectItemsThisProductMadeOf:
    "Select the item/s this product is made of. Set the quantity of each supply item that will be deducted per sale order of this product.",
  selectLocation: "Select Location",
  selectNumberOfTables: "Select the number of tables",
  selectOutlet: "Select Outlet",
  selectProductForTips: "Select Product for Tips",
  selectProducts: "Select Products",
  selectRedeemableProducts: "Select Redeemable Products",
  selectSeller: "Select Seller",
  selectSource: "Select source",
  selectStaff: "Select Staff",
  selectStatus: "Select status",
  selectStockLevel: "Select Stock Level",
  selectStockUnit: "Select Stock Unit",
  selectSupplyItem: "Select supply item",
  selectTax: "Select Tax",
  selectToIncludeCategory: "Select to include this product in a category..",
  selectToIncludeProductSetInCategory: "Select to include this product set in a category..",
  selectVenue: "Select Venue",
  selectVouchers: "Select Vouchers",
  sellOnPos: "Sell on POS",
  sellOnPosRoleAppDesc: "This role does not have access to the online Venue Dashboard.",
  sellOnPosRoleDashboardDesc: "Allows access to Venue App’s Selling and Online Order.",
  seller: "Seller",
  selling: "Selling",
  sellingOnPos: "Selling on POS",
  sellingQty: "Selling Qty",
  sellingQtyMustBeGreaterThanZero: "Selling quantity must be greater than zero.",
  sendTo: "Send to",
  sendToDian: "Send to Dian",
  sendToDianSuccessfully: "Send to Dian successfully",
  sendToSiigo: "Send to Siigo",
  sendToSiigoSuccessfully: "Send to Siigo successfully",
  serialNumber: "Serial Number",
  serialNumberAbbv: "S/N",
  set: "Set",
  setAnAmountToLimitPostPaid: "Set an amount to limit a guest's allowed postpaid credits.",
  setAsDefaultTax: "Set as default tax",
  setAsNonRefundable: "Set as non-refundable",
  setCode: "Set Code",
  setExpirationPeriod: "Set expiration period",
  setUpYourAccount: "Setup your account!",
  sets: "Sets",
  settings: "Settings",
  share: "Share",
  shareThisLink:
    "Share this link to open {0} on any device! Copy the link and share it with any of your staff.",
  shift: "Shift",
  shiftAlreadyClosed: "This shift is already closed.",
  shiftClosed: "Shift Closed",
  shiftEnd: "Shift End",
  shiftId: "Shift ID",
  shiftStart: "Shift Start",
  shifts: "Shifts",
  shiftsAndTransactions: "Shifts and Transactions",
  shouldBeANumber: "Should be a number.",
  show: "Show",
  showErrorInvoiceOnly: "Show error invoice only",
  showHideColumns: "Show / Hide Columns",
  showLess: "Show Less",
  showMore: "Show More",
  showNumOutDateDevices:
    "Our system shows that only {0} devices have updated to latest version. Please update the remaining devices to the latest version.",
  showOnlineMenu: "Show in Online Menu",
  signIn: "Sign-in",
  signInButton: "Sign-in Button",
  signUp: "Sign Up",
  siigo: "Siigo",
  siigoAccount: "Siigo Account",
  siigoAccountConnectedToDian: "Siigo account connected to Dian",
  siigoDescBill:
    "It is necessary that the discount type be configured as 'Percentage' and that it be active in Siigo.",
  siigoDescInventoryGroup:
    "In Siigo, an inventory group is used to organize and categorize similar products. If a product in venue doesn't have a specific inventory group assigned, it will automatically be placed into the Default Inventory Group.",
  siigoDescPaymentMethod:
    "Enter the name of the product or service your tips will be associated with. This product will be created (or updated, if it already exists) in Siigo and mapped with tips in venue for invoicing purposes.",
  siigoDescProductForTips: "The product that will be used to invoice the tips you receive",
  siigoDescSalesTax:
    "Link tax on PouchNATION to corresponding taxes in Siigo. If you do not select a tax in Siigo, the invoices which include the tax will not be created.",
  siigoDescSeller: "This seller will be the seller for invoice created in Siigo",
  siigoError: "Siigo Error",
  siigoIntegrationDetail: "Siigo Integration Detail",
  siigoInvNo: "Siigo Inv No.",
  siigoInvoiceNumber: "Siigo Invoice Number:",
  sku: "SKU",
  skuAlreadyExists: "SKU already exists.",
  skuStockKeepingUnit: "SKU (Stock Keeping Unit)",
  someArePartOfCompositeNote:
    "{0} {1} below cannot be deleted as they are part of other composite products. To delete these, remove them from the composite products or delete them {2} with the composite products that carry them.",
  someShiftsHaveUnsynced:
    "Some shifts have unsynced transactions. Please check the shifts' devices to push the venue data.",
  somethingWentWrong: "Something went wrong.",
  somethingWentWrongPlsTryAgain: "Something went wrong. Please try again",
  sorryVenueSuspended: "Sorry, this venue has been suspended.",
  source: "Source",
  sourceLocation: "Source location",
  sourceLocationOriginalQty: "Source location original qty",
  sourceLocationRemainQty: "Source location remain qty",
  sourceLocationTransferQty: "Source location transfer qty",
  specificCategories: "Specific Categories",
  specificProducts: "Specific Products",
  spend: "Spend",
  staff: "Staff",
  staffCanAccess: "Staff can access the POS app and perform actions.",
  staffList: "Staff List",
  staffName: "Staff Name",
  staffNameAndOrTagId: "Staff Name and/or Tag ID",
  staffProfile: "Staff Profile",
  staffProfileDeleted: "Staff profile deleted",
  staffProfileList: "Staff Profile List",
  staffProfileName: "Staff Profile Name",
  staffProfileNameCreated: "Staff Profile “{0}” created.",
  staffProfileNamePlaceholder: "Type the name of this staff profile...",
  staffProfiles: "Staff Profiles",
  staffRemoved: "Staff removed.",
  staffTagWithColonSymbol: "Staff Tag:",
  staffWithColonSymbol: "Staff:",
  standard: "Standard",
  start: "Start",
  startStocktake: "Start Stocktake",
  startedOn: "Started on",
  state: "State",
  status: "Status",
  staying: "Staying",
  step: "Step",
  stockAdjSupplyItemsTxtConfirm: "Are you sure you want to adjust stock for supply items in {0}",
  stockAdjustment: "Stock Adjustment",
  stockBelowParLevel: "Stock is below par level",
  stockLevel: "Stock Level",
  stockLevelAlerts: "Stock Level Alerts",
  stockLevelNotificationSettings: "Stock Level Notification Settings",
  stockLevels: "Stock Levels",
  stockManager: "Stock Manager",
  stockManagerRoleAppDesc:
    "This role has no roles related to the App and would not be able to login on the device.",
  stockManagerRoleDashboardDesc:
    "Allows access to Venue Dashboard that is limited only to Inventory’s Stocktake.",
  stockReachedReorderPoint: "Stock has reached reorder point",
  stockUnit: "Stock Unit",
  stockVariance: "Stock Variance",
  stockWeightPerN: "Stock weight per {0}",
  stocks: "Stocks",
  stocktake: "Stocktake",
  stocktakeAlreadyExists: "Stocktake already exists",
  stocktakeSaved: "Stocktake Saved",
  stocktakeWithId: "Stocktake #ST-{0}",
  streetVillage: "Street address, Town / Village",
  subTotal: "Subtotal",
  subscribeAndRegister: "Subscribe and register now to get a",
  subscription: "Subscription",
  subscriptionCanceledReason: "You have cancelled your subscription.",
  subscriptionExpiredReason: "You have cancelled your subscription.",
  subscriptionSuspended:
    "The subscription has ended. Please contact the venue owner to renew the subscription before {1} to retain access to {2}.",
  subscriptionSuspendedIn5:
    "This venue is about to be suspended in 5 days. Please contact the venue owner to renew the subscription before {0} to retain access to {1}.",
  subscriptionSuspendedIn5Manager:
    "Your venue is going to be suspended in less than 5 days. Please renew your subscription before {0} to retain access to {1}.",
  subscriptionSuspendedManager:
    "{0} Please renew your subscription before {1} to retain access to {2}.",
  subscriptionSuspendedToday:
    "Your venue is going to be suspended today. Please contact the venue owner to renew the subscription to retain access to {0}.",
  subscriptionSuspendedTodayManager:
    "Your venue is going to be suspended today. Please renew your subscription today to retain access to {0}.",
  subscriptionUnpaidReason: "There seems to be a problem with your payment details.",
  subscription_only_owner_access:
    "Only the Venue Owner who subscribed to this plan can access this subscription",
  success: "Success",
  sugar: "sugar",
  suggested: "Suggested",
  summary: "Summary",
  supplyCost: "Supply Cost",
  supplyItem: "Supply Item",
  supplyItemAlreadyExists: "Supply item name already exists.",
  supplyItemDeleted: "Supply Item Deleted",
  supplyItemNameCreated: "Supply Item “{0}” created.",
  supplyItemSummaryPage: "Supply Item Summary Page",
  supplyItems: "Supply Items",
  supplyTaxes: "Supply Taxes",
  supplyUnit: "Supply Unit",
  supplyValue: "Supply Value",
  supportPouchnation: "support@pouchnation.com",
  suspended: "Suspended",
  syncFromSiigo: "Sync from Siigo",
  syncFromSiigoDesc:
    "If you add or update your information in Siigo (invoice type, seller, inventory group, payment method or tax), you will need to sync it again to map with its equivalent in PouchNATION.",
  syncFromSiigoSuccessfully: "Sync from Siigo Successfully",
  syncToDian: "Sync to Dian",
  syncToMews: "Sync to MEWS",
  tableManagement: "Table Management",
  tableQRCode: "Table QR Codes (Per table)",
  tables: "Tables",
  tagBanned: "Tag banned.",
  tagId: "Tag ID",
  tagIds: "Tag IDs",
  tagInfo: "Tag Info",
  tagUID: "Tag UID",
  tagUnbanned: "Tag unbanned.",
  tags: "Tags",
  tagsIssued: "Tags Issued",
  tapCount: "Tap Count",
  tapOnTheAPKFileToStartTheInstallationProcess:
    "Tap on the APK file to start the installation process. Follow the on-screen instructions to complete the installation. Once installed, open the app from your app drawer or home screen.",
  tapStatus: "Tap Status",
  tax: "Tax",
  taxAddedName: "Tax “{0}“ added.",
  taxAlreadyExists: "Tax already exists.",
  taxCode: "Tax Code",
  taxEditNote:
    "Note: Products linked to this tax will be updated automatically. This will not affect past sales.",
  taxEg: "e.g. “Value Added Tax“",
  taxIncluded: "Tax included",
  taxInvoiceNo: "Tax Invoice#:",
  taxInvoiceNumber: "Tax Invoice Number",
  taxName: "Tax Name",
  taxNumber: "Tax Number",
  taxOnPurchasesAccount: "Tax on Purchases Account",
  taxOnPurchasesAccountMessage: "VAT and other taxes on purchase orders.",
  taxOnPurchasesAccountPlaceholder: "Liability/Tax on Purchases",
  taxOnSalesLiabilityAccount: "Tax on Sales Liability Account",
  taxOnSalesLiabilityAccountMessage: "The default account for taxes charged for each product.",
  taxOnSalesLiabilityAccountPlaceholder: "Liability/Tax on Sales",
  taxPercentageAndValue: "Tax ({0}%): {1}",
  taxRate: "Tax Rate",
  taxSettings: "Tax Settings",
  taxes: "Taxes",
  termsAndConditions: "Terms and Conditions",
  termsInAllowingAccess:
    "By clicking on Allow Access, you authorize {0} to use, receive, and store your information. Please review {1}’s {2} and {3}. You can stop it at any time on the Integrations settings of your PouchNATION account.",
  termsInAllowingAccess2:
    "By clicking on Allow Access, you authorize {0} to use, receive, and store your information. You can stop it at any time on the Integrations settings of your PouchNATION account.",
  termsInAllowingAccess3:
    "By clicking on Allow Access, you authorize {0} to store your {1} information. Please review our {2} and {3}.",
  termsInSignUp: "By signing up, I agree to PouchNATION's {0}, {1}, and {2}.",
  termsOfService: "terms of service",
  termsOfUse: "Terms of Use",
  textbox: "Textbox",
  theActualNumberOfGuests: "The actual number of guests that are checked-in/out",
  theAmountTheGuestWillPay: "This is the amount the guest will pay for their booking.",
  theBookingStatus: "The booking status from source.",
  theGuestHasNoAssignedRoom: "The guest has no assigned room!",
  theLastVersion: "Latest  App Version: ",
  theLastVersionOfAppAvailable:
    "The latest version of the app {0} is now available in the Google Play Store.",
  theLinkYouAreTryingToAccess: "The link you are trying to access is not from your Venue.",
  theLinkYouAreTryingToAccessDoesNotBelongToLocation:
    "The link you are trying to access is not from your assigned location",
  theShiftAndTransactionDetails:
    "The shift and transactions details will display here once the shift is closed on the register.",
  theUpdateShouldBeAppliedAutomatically:
    "The update should be applied automatically but you can also open the link below or scan the QR code to download the latest app version.",
  thereIsSomeError:
    "Uh-oh! There is some error in our end. Please bear with us and check again later.",
  theseNthOtherProducts:
    "{0} {1} other product(s) with this location and other locations will remain in your inventory.",
  theseProductsCannotBeDeleted: "These products cannot be deleted.",
  thisAppRequests: "This app requests the following information:",
  thisAppWouldRead: "This app would read and/or write the following information:",
  thisEmailIsAlreadyAdded: "This email is already added.",
  thisEmailIsInvalid: "This email is invalid.",
  thisFieldIsRequired: "This field is required.",
  thisGuestHasNotCheckedOutYet: "This guest has not checked out yet",
  thisIsForDirectIntegrationWithPaymentTerminalPartner:
    "This is for direct integration with Payment Terminal Partner",
  thisLocationHasTables: "This location has tables and seating arrangements.",
  thisMonth: "This month",
  thisProductNameAlreadyExists: "This product name is already exists",
  thisProductsHasMultipleVariant:
    "This product has multiple options such as size, color, flavor, etc.",
  thisQuestionIsLinkedToNProfiles: "This question is linked to {0} guest profile(s).",
  thisServesAsAnOfficialReceipt: "- THIS SERVES AS AN OFFICIAL RECEIPT -",
  thisShiftHasUnsyncedTransactions: "This shift has unsynced transactions",
  thisSkuExists: "This SKU already exists",
  thisStaffHasNpLongerAccess:
    "This staff will no longer have access to {0}. This action cannot be undone.",
  thisTagIdIsAlreadyBanned: "This Tag ID is already banned.",
  thisVenueFreeTrial: "This venue will be on free-trial for the first 30-days.",
  thisWeek: "This week",
  ticketNo: "Ticket No",
  ticketing: "Ticketing",
  time: "Time",
  timeEnded: "Time Ended",
  timeRange: "Time Range",
  timeStarted: "Time Started",
  timeout: "Timeout",
  tip: "Tip",
  tipOptions: "Tip Options",
  tipReport: "Tip Report",
  tips: "Tips",
  tipsAndCharges: "Tips and Charges",
  to: "to",
  toAddMoreCreditsToATag: "To add more credits to a tag, guests may be able to",
  toBeCheckedIn: "To Be Checked-in",
  toBeCheckedOut: "To Be Checked-Out",
  toContinueCreatingDiscount: "To continue creating this discount, create a location first.",
  toContinueCreatingProducts: "To continue creating this product, create a location first.",
  toContinueCreatingStaff: "To continue creating this staff, create a location first.",
  toContinueCreatingStocktake: "To continue creating this stocktake, create a location first.",
  toDeleteItem: "to delete this item.",
  toScheduleACall: "To schedule a call with our customer support.",
  toViewDetailedInstructions: "To view detailed instructions",
  today: "Today",
  together: "Together",
  topCategoriesBySales: "Top Categories by Sales",
  topLocations: "Top Locations",
  topProducts: "Top Products",
  topSpendingGuests: "Top Spending Guests",
  topupAndReturnCredits: "Topup and Return Credits",
  topupCredits: "Topup Credits",
  topupCreditsRoleAppDesc: "This role does not have access to the online Venue Dashboard.",
  topupCreditsRoleDashboardDesc: "Allows access to Venue App’s Topup",
  total: "Total",
  totalAmount: "Total Amount",
  totalBookings: "Total Bookings",
  totalCreditsKept: "Total Credits Kept (after Checkout)",
  totalCreditsKeptByGuests: "Total Credits Kept by Guests",
  totalCreditsKeptTooltip: "Total amount of Credits the guests kept after check out",
  totalCreditsReceived: "Total Credits Received",
  totalCreditsReceivedFromVenues: "Total Credits Received from other Venues",
  totalCreditsReceivedTooltip:
    "Total amount of Credits Received from guests checked in with credits retained from other venues",
  totalExpectedEndingBalance: "Total Expected Ending Balance",
  totalFreeCreditsIssued: "Total Free Credits Issued",
  totalFreeCreditsRemoved: "Total Free Credits Removed",
  totalInStock: "Total In Stock",
  totalItems: "Total items",
  totalOpeningBalance: "Total Opening Balance",
  totalPaid: "Total Paid",
  totalProductSales: "Total Product Sales",
  totalRefunded: "Total Refunded",
  totalRefundedCredits: "Total Refunded Credits",
  totalRemoved: "Total Removed",
  totalReturnCredits: "Total Return Credits",
  totalReturnedCredits: "Total Returned Credits",
  totalSales: "Total Sales",
  totalSpent: "Total Spent",
  totalSpentToDate: "Total Spent to Date",
  totalStocksPerLocation: "Total Stocks per Location",
  totalTagsUsed: "Total Tags Issued",
  totalTax: "Total Tax",
  totalTopUps: "Total Topups",
  totalTopupCredits: "Total Topup Credits",
  totalTransactions: "Total Transactions",
  totalUnspentCredits: "Total Unspent Credits",
  totalUnspentCreditsDesc:
    "Sum of all unspent credits for guests that are linked to {0} and are scheduled for check-out between the dates on your filter above.",
  totalUse: "Total Use",
  totalVisits: "Total Visits {0}",
  totals: "Totals",
  trackAndManage: "Track and manage your manual inventory counts.",
  trackEveryGuestCheckedIn:
    "Track every guest checked-in in your venue and see their favorite product and location",
  trackWhichItemsLow: "Track which items are low in stocks and needs to be resupplied",
  trackedInventory: "Tracked Inventory",
  tradeTypeTransactionNumber: "{0} Trans No",
  transaction: "Transaction",
  transactionId: "Transaction ID",
  transactionInfoPage: "Transaction Info Page",
  transactionStatus: "Transaction Status",
  transactionType: "Transaction Type",
  transactions: "Transactions",
  transactionsFailedToProcess: "transactions failed to process.",
  transactionsPage: "Transactions Page",
  transfer: "Transfer",
  transferSupplyItems: "Transfer Supply Items",
  transferSupplyItemsSuccessfully: "Transfer Supply Items successfully",
  transferSupplyItemsTxtConfirm: "Are you sure you want to transfer supply items from {0} to {1}?",
  try: "Try:",
  turnOffPos: "Turn off selling of {0} {1}?",
  turnOnPos: "Turn on selling of {0} {1}?",
  txnId: "Txn Id: #",
  type: "Type",
  typeAQuestion: "Type a question",
  typeAReason: "Type a reason...",
  typeAnOption: "Type an option",
  typeDisconnect: "Type DISCONNECT in uppercase to confirm",
  typeHere: "Type here...",
  typeToSearch: "Type to search for an item!",
  typeToSetAccountCode: "Type to set/select an account code.",
  unableToDelete: "Unable to Delete",
  unbaleToUploadMoreThanMb: "Unable to upload images with more than 1mb.",
  unban: "Unban",
  unbanTag: "Unban Tag",
  unbanningWillMakeTagAccessible: "Unbanning will make this tag accessible in your venue.",
  unbill: "Unbill",
  uncategorized: "Uncategorized",
  undefinedError: "Undefined Error",
  undefinedErrorPlsTryAgain: "Undefined Error. Please refresh page and try again",
  undo: "UNDO",
  unignore: "Unignore",
  unignoreAllOpenShifts: "Unignore All Open Shifts",
  unignoreAllShifts: "Unignore All Shifts",
  unitPrice: "Unit Price",
  unitSold: "Unit Sold",
  unitsSold: "Units Sold",
  unknown: "Unknown",
  upToDate: "Up to date",
  updatedOn: "updated on {0}",
  updatedVersionOfTheAppIsNowAvailable: "Updated Version of the App is Now Available!",
  upgradeNow: "Upgrade Now",
  upgradePlanHeader:
    "Supercharge your venue and access more premium features when you upgrade your plan now!",
  upgradeYourPlan: "Upgrade Your Plan! ✨",
  upload: "Upload",
  uploadExcelFileToImport: "Upload excel file to Import",
  uploadPhoto: "Upload Photo",
  uponRegistration: "upon registration or with any top-up staff / kiosk within the venue.",
  usage: "Usage",
  usageDatePeriod: "Usage Date Period",
  useThisVenueDetails: "Use this Venue details",
  useVenueAppAndLogin:
    "Use the Venue App and login with your activated NFC Tag or with your current username or password. Don’t have the app yet? Ask your venue manager or {0}.",
  used: "Used",
  user: "User",
  username: "Username",
  usesLastIssuedTo: "uses, last issued to",
  usingTheURL: "Using the URL",
  usingTheURLdesc:
    "Open your web browser and paste the provided URL into the address bar and press Enter and this will take you to the app's page on the Google Play Store.",
  validUntilWithColonSymbol: "Valid Until:",
  value: "Value",
  valueBetween: "Value should be between",
  valueRedeemed: "Value Redeemed",
  variant: "Variant",
  variantAlreadyExist: "Variant already exist",
  variantIsCurrentlyBeingUsed: "Variant is currently being used in the following product",
  variants: "Variants",
  vatReg: "Tax No.",
  venue: "Venue",
  venueCurrency: "Venue Currency",
  venueDashboard: "Venue Dashboard",
  venueDetails: "Venue Details",
  venueInLitePlan: "This venue is currently on the Lite Plan.",
  venueInformation: "Venue Information",
  venueLocation: "Venue Location",
  venueLogo: "Venue Logo",
  venueMode: "Venue Mode",
  venueName: "Venue Name",
  venueNameAlreadyExist: "Venue name already exists, please choose another name",
  venueSettings: "Venue Settings",
  venueSuspended: "Venue Suspended",
  veryHighDenominations: "Very High Denominations",
  view: "View",
  viewAccountOnSiigo: "View Account on Siigo",
  viewAllTransactions: "View All Transactions",
  viewAndExportDiscountReport:
    "View and export the list of your discounts and the total amount deducted for each discount",
  viewAndExportEachClosedRegister: "View and Export each closed shift report made by your staff",
  viewAndExportFreeCreditsReport: "View and export free credits issued and removed.",
  viewAndExportSalesReportByCategories: "View and export your sales report by categories",
  viewAndExportVoucherReport:
    "View and export all vouchers applied on redeemed products and the total amount of retail and supply redeemed by each voucher",
  viewBillingStatement: "View Billing Statement",
  viewBookingInfo:
    "View booking information from different platforms such as MEWS, PouchNATION Ticketing, and more!",
  viewBy: "View by",
  viewCategoryDetails: "View Category Details",
  viewDetails: "View Details",
  viewDevice: "View Device",
  viewDiscountDetails: "View Discount Details",
  viewEveryTransactions:
    "View every transactions made in your venue within a certain period of time",
  viewInfo: "View Info",
  viewIntegrations: "View Integrations",
  viewInvoice: "View Invoice",
  viewListInvoiceTitle: "View list of invoice for checked-out guests in your venue",
  viewNOfNTransactions: "View {0} / {1} Transactions",
  viewNTransactions: "View {0} Transactions",
  viewPlansAndPricing: "View Plans and Pricing",
  viewPreview: "View Preview",
  viewPrinter: "View Printer",
  viewTaxSettings: "View Tax Settings",
  viewTheSalesSummaryOfYourVenue:
    "View the sales summary of your venue and its top locations, products, and more.",
  viewVoucherDetails: "View Voucher Details",
  void: "Void",
  voidAuthorization: "Void Authorization",
  voidConfirmation: "Are you sure you want to void {0} This action cannot be undone.",
  voidId: "Void {0}?",
  voidInfo: "Void Info",
  voidReason: "Void Reason",
  voidStocktake: "Void Stocktake",
  voided: "Voided",
  voidedBy: "Voided by",
  voidedItems: "Voided Items",
  voidedOn: "Voided on",
  voidedValue: "Voided Value",
  voucher: "Voucher",
  voucherApplied: "Voucher Applied",
  voucherAvailable: "This voucher is available for issuing in your venue.",
  voucherCreated: "{0} added.",
  voucherDeleted: 'Voucher "{0}" deleted.',
  voucherIssue: "Voucher Issue",
  voucherMovementPage: "Voucher Movement Page",
  voucherName: "Voucher Name",
  voucherNameAlreadyExist: "This voucher name already exists.",
  voucherReachedIssuanceLimit: "This voucher has reached its maximum issuance limit of {0}.",
  voucherRemove: "Voucher Remove",
  voucherReport: "Voucher Report",
  voucherRetailValue: "Vouchers Retail Value",
  voucherSupplyValue: "Vouchers Supply Value",
  voucherType: "Voucher Type",
  voucherUpdated: "Changes saved.",
  vouchers: "Vouchers",
  vouchersCreationLimitInfo:
    "You may only create a maximum of 8 vouchers per venue. You may delete expired or inactive vouchers to make room for more vouchers.",
  vouchersIssued: "Vouchers Issued",
  vouchersListPage: "Vouchers List Page",
  vouchersMaximumNumberReached: "Maximum number of vouchers reached.",
  vouchersRedeemed: "Vouchers Redeemed",
  vouchersRemoved: "Vouchers Removed",
  vouchersUsage: "Vouchers Usage",
  warning: "Warning",
  warningResyncDesc:
    "There are still transactions missing from the e-invoice system due to offline devices.\nPlease ensure all venue devices are connected to the internet and have their data pushed to the system to complete the synchronization, then click on button “Re-sync E-Invoice” to update the invoice. ",
  warningSiigoIntegrationDesc:
    "To comply with Dian regulations, collecting the following guest information is mandatory: Name, Email, ID Type, and ID.\nBy integrating with Siigo, these questions will automatically appear in guest profiles.\n\nPlease do not uncheck them to ensure we gather all necessary data during check-in. We will not be responsible for any missing information due to non-compliance.\n\n",
  weDidNotFoundReports: "We did not found any reports for your filters.",
  weekMon2Sun: "Weekly (Mon to Sun)",
  weekSun2Sat: "Weekly (Sun to Sat)",
  weeksAfterCheckout: "weeks after check-out",
  weightInGrams: "Weight in grams",
  welcomeBack: "Welcome back!",
  whatAreFreePostpaidCredits: "What are Free Credits and Postpaid Credits?",
  whenAnItemAlertLevel: "When an item falls to its alert level.",
  whole: "Whole",
  wholeProduct: "Whole Products",
  withPendingPayments: "With Pending Payments",
  withRemainingBalance: "With Remaining Balance",
  wouldReadOrWrite: "{0} would read and/or write the following information:",
  wristbandKeeping: "Wristband Keeping",
  wristbandTag: "Wristband Tag",
  wristbandTagKeeping: "Wristband Tag Keeping",
  wristbandTagSettings: "Wristband Tag Settings",
  xConnectedFailed:
    "We can't seem to recognize this access token. Please refer to the instructions below to retrieve your proper access token.",
  xConnectedSuccessfully: "{0} Connected Successfully",
  year: "year",
  years: "years",
  yes: "Yes",
  yesterday: "Yesterday",
  youAreAboutToDelete: 'You are about to delete the {0} "{1}". This cannot be undone.',
  youAreAboutToDeleteAQuestionThatIsLinked:
    "You are about to delete a question that is linked to a guest profile. This cannot be undone.",
  youAreAboutToDeleteGuestProfile:
    "You are about to delete this guest profile. This cannot be undone.",
  youAreAboutToDeleteMultiple: "You are about to delete {0} {1}. This cannot be undone.",
  youAreAboutToDeletePaymentMethod: "You are about to remove “{0}“ payment method.",
  youAreAboutToDeleteQuestion: "You are about to delete this question. This cannot be undone.",
  youAreAboutToDeleteStaffName:
    "You are about to remove {0} as your staff. This staff will no longer have access to {1}. This action cannot be undone.",
  youAreAboutToDeleteStaffProfile:
    "You are about to delete this staff profile. This cannot be undone.",
  youAreAboutToDeleteTax: "You are about to remove “{0}“ tax.",
  youAreAboutToDisconnect:
    "You’re about to disconnect and permanently remove all information from this app.",
  youAreAboutToRemoveFromMailingList:
    "You are about to remove {0} from this mailing list. This account won't be able to receive stock level alerts.",
  youAreAboutToRemoveMultipleProduct:
    "You are about to remove {0} products and their variants from your inventory and all your locations. This cannot be undone.",
  youAreAboutToRemoveProduct:
    "You are about to remove this product from your inventory and all your locations. This cannot be undone.",
  youAreAboutToRemoveVariantProduct:
    "You are about to remove this product and its {0} variants from your inventory and all your locations. This cannot be undone.",
  youAreAssigned: "You are assigned with the following roles:",
  youAreLoggedInAs: "You are logged in as {0}",
  youCanChangeThisLater: "You can change this later!",
  youCanNowLogin: "You can now login using your email and password.",
  youCanReviewQuestionInEachGuestProfile: " You can review question in each guest profile ",
  youDontHavePermission: "You don’t have permission to access this link",
  youHaveUnsavedChanges: "You have unsaved changes on this page.",
  youllFindYourBookingsHere: "You’ll find your bookings here.",
  yourGeneralSettings: "Your general settings such as venue name and currency.",
  yourMailingIsEmpty: "Your mailing list is still empty.",
  yourSubscriptionCanceled: "Your {0} plan subscription for your venue, {1}, was canceled.",
  yourSubscriptionEnded: "Your {0} plan subscription for your venue, {1}, has ended.",
  yourVenues: "Your Venues",
  youveReachedTheEndOfTheList: "You’ve reached the end of the list.",
  zeroInventory: "Zero Inventory",
};

export default Object.freeze(locale);

import { getInvoiceDetail, resyncInvoiceApi, sendToInvoicesApi } from "apis/siigo.api";
import {
  Button,
  ButtonLink,
  DataTable,
  // Divider,
  HeaderC,
  Icon,
  Image,
  ModuleWrapper,
  Text,
  Title,
  Toast,
} from "components";
import { useApi, useModal, useMount, useRouter } from "hooks";
import moment from "moment";
import { Path } from "paths";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { formatNumberToMoney } from "services";
import lang from "translations";
import SendToModal from "../send-to-modal";
import classNames from "classnames";
import { AppContext } from "contexts";
import { InvoicedSiigo } from "enums";
import { prettifyInvoiced } from "services/pretty.service";
import { detailInvoiceMapper } from "mappers/siigo.mapper";
import { WarningInvoiceIcon } from "images";

const InvoiceDetail = () => {
  const { setGlobalLoading } = useContext(AppContext);
  const { query, history } = useRouter();
  const id = query.id;
  const sendToModal = useModal();
  const [errSend, setErrSend] = useState([]);

  const { mappedData, loading, error, request } = useApi({
    api: getInvoiceDetail,
    params: { id },
    mapper: detailInvoiceMapper,
  });
  const title = useMemo(() => lang.invoice + " " + (mappedData.id || ""), [mappedData.id]);
  const invoiceData = mappedData.invoiceData;
  const warningResync = mappedData.warningResync;

  useMount(async () => {
    request();
  });

  const sendToAction = useCallback(async () => {
    const data = [
      {
        invoiceId: id,
        synced: true,
        syncedDian: false,
      },
    ];
    try {
      setGlobalLoading(true);
      setErrSend([]);
      await sendToInvoicesApi(data);
      Toast({ content: lang.sendToSiigoSuccessfully, success: true, icon: "check" }).open();
    } catch (error) {
      if (error.messages && error.messages.length) {
        setErrSend(error.messages);
      }
      // Toast({
      //   content: error.messages[0] || lang.somethingWentWrong,
      //   error: true,
      //   icon: "exclamation-fill",
      // }).open();
    } finally {
      await request();
      setGlobalLoading(false);
    }
  }, [id, request, setGlobalLoading]);

  const onResync = async () => {
    try {
      await resyncInvoiceApi([
        {
          invoiceId: id,
          synced: true,
        },
      ]);
      await request({ id });
      Toast({ content: lang.resyncSuccessfully, success: true, icon: "check" }).open();
    } catch (error) {
      Toast({ content: lang.somethingWentWrong, error: true, icon: "exclamation-fill" }).open();
    }
  };

  const goToList = useCallback(() => {
    history.push(Path.SIIGO_INVOICES);
  }, [history]);

  const openSendInvoice = useCallback(
    async (toDian) => {
      setErrSend([]);
      sendToModal.show({ ids: [id], toDian, setErr: setErrSend });
    },
    [id, sendToModal]
  );

  // const actionSelectOption = useMemo(() => {
  //   return [
  //     {
  //       text: lang.sendToSiigo,
  //       onClick: () => sendToAction(),
  //     },
  //     {
  //       text: lang.sendToDian,
  //       onClick: () => openSendInvoice(true),
  //     },
  //   ];
  // }, [openSendInvoice, sendToAction]);

  return (
    <ModuleWrapper
      loading={loading}
      error={error}
      header={<HeaderC returnText={lang.eInvoice} onClick={goToList} />}
    >
      <div className="flex gap-4">
        <Title xl className="font-semibold">
          {title}
        </Title>
        {renderState(mappedData.state)}
      </div>
      {!!warningResync && (
        <div className="px-6 py-4 mt-3 rounded-lg bg-yellow-100 text-yellow text-md">
          <div className="flex gap-2">
            <Image src={WarningInvoiceIcon} className="text-yellow w-5 h-5" />
            <span className="font-bold">{lang.warning}</span>
          </div>
          <div className="whitespace-pre-wrap">{lang.warningResyncDesc}</div>
        </div>
      )}
      {!!(mappedData.errorReason && mappedData.errorReason.length) && (
        <div className="p-6 bg-red-100 mt-3 rounded-lg">
          <div className="bg-red-100 text-red-lighter pr-2 font-bold">
            <Icon name={"warning"} /> {lang.error}:
          </div>

          {mappedData.errorReason.map((msg) => (
            <Text size="text-md" color="text-red-lighter">
              {msg}
            </Text>
          ))}
        </div>
      )}
      {errSend && !!errSend.length && (
        <div className="p-6 bg-red-100 mt-3 rounded-lg">
          <div className="bg-red-100 text-red-lighter pr-2 font-bold">
            <Icon name={"warning"} /> {lang.error}:
          </div>

          {errSend.map((msg) => (
            <Text size="text-md" color="text-red-lighter">
              {msg}
            </Text>
          ))}
        </div>
      )}

      <div className="bg-white flex justify-between items-center py-4 px-6 mb-8 mt-3 rounded-lg">
        <div>
          <Text className="text-black-dark flex" size="text-lg">
            {lang.siigoInvoiceNumber}
            {mappedData?.externalFile && (
              <ButtonLink
                className="ml-2"
                suffix
                icon="arrow-diagonal-right"
                newTabPath={mappedData?.externalFile}
                color="text-black-dark"
              >
                <span className="text-20 font-semibold">{mappedData.siigoInvoiceNumber}</span>
              </ButtonLink>
            )}
          </Text>
          <Text className="text-black-dark" size="text-lg">
            {lang.cufe}: <span className="text-20 font-semibold">{mappedData.cufe}</span>
          </Text>
        </div>
        <div className="flex gap-4 h-fit">
          {warningResync && <Button onClick={onResync}>{lang.resyncEInvoice}</Button>}
          {mappedData.btn?.sendSiigo && (
            <Button onClick={() => sendToAction()}>{lang.sendToSiigo}</Button>
          )}
          {mappedData.btn?.sendDian && (
            <Button onClick={() => openSendInvoice(true)}>{lang.sendToDian}</Button>
          )}
          {/* <Dropdown trigger="click" options={actionSelectOption}>
            <div className="cursor-pointer border rounded border-solid border-white-darker flex align-center bg-pelorous justify-center p-sm w-24 text-white">
              <div className="text-base mr-1">{lang.sendTo}</div>
              <Icon name="sort-descending1" className="text-white text-xxs" />
            </div>
          </Dropdown> */}
        </div>
      </div>

      <div className="bg-white py-7 px-10">
        <div className="flex justify-center items-center gap-14">
          <Image src={invoiceData?.venueLogo || ""} style={{ width: 180, height: 180 }} />
          <div style={{ minWidth: 350 }}>
            <Title xxl color="text-black">
              {lang.invoice}
            </Title>
            <div className="flex justify-between">
              <Text size="text-20">{lang.invoiceNumber}:</Text>
              <Text size="text-20" strong>
                INV-{mappedData.id}
              </Text>
            </div>
            <div className="flex justify-between">
              <Text size="text-20">{lang.invoiceDate}:</Text>
              <Text size="text-20" strong>
                {moment(mappedData.issuedDate).format("DD MMM YYYY")}
              </Text>
            </div>
          </div>
        </div>
        {/* <Divider /> */}
        <div
          className="flex justify-between py-8 px-10 mt-10 mb-6 gap-20"
          // style={{ background: "#634B660D" }}
        >
          <Info
            title={lang.from}
            name={invoiceData?.venueName}
            info={[
              ["Add", invoiceData?.venueAddress],
              ["NIT", invoiceData?.venueTaxNumber],
              ["Tel", invoiceData?.venuePhoneNumber],
            ]}
          />
          <Info
            title={lang.to}
            name={mappedData.customerName}
            info={[
              ["Email", invoiceData?.customerEmail],
              [invoiceData?.customerIdType, invoiceData?.customerId],
              ["Tel", invoiceData?.customerPhoneNumber],
            ]}
          />
        </div>

        <ItemTable items={invoiceData?.items} loading={loading} />

        <Title lg className="mt-8 mb-10 rounded-lg" color="text-black">
          {lang.totalItems}: {invoiceData?.items?.length}
        </Title>
        <div className="flex gap-16">
          <div className="flex-1 bg-gray-lightest rounded-lg h-fit">
            <Line title={lang.paymentTerms} bold />
            {invoiceData?.payments?.map(({ name, amount }) => (
              <Line
                key={name}
                title={name}
                text={`$ ${formatNumberToMoney(amount, true, true)}`}
                bold
              />
            ))}
          </div>
          <div className="flex-1 bg-gray-lightest rounded-lg h-fit">
            {Object.entries(mappedData?.totalObj || {}).map(([k, v], i) => (
              <Line
                key={k}
                title={lang[k]}
                text={`$ ${formatNumberToMoney(v, true, true)}`}
                bold={i === 0 || i === 3}
              />
            ))}
          </div>
        </div>
      </div>
      <SendToModal {...sendToModal} />
    </ModuleWrapper>
  );
};

const Info = ({ title, name, info = [], right = false }) => {
  return (
    <div className="flex-1">
      <Title color="text-pelorous" className="flex pb-2 border-b border-pelorous capitalize">
        {title}
      </Title>
      <Text size="text-20" color="text-black my-4" fontWeight="font-bold">
        {name}
      </Text>
      <div className={classNames(right && "text-right", "flex flex-col gap-4")}>
        {info.map(([k = "", v = ""]) => (
          <div className="flex justify-between">
            <Text size="text-base" className="font-medium" color="text-gray-dark">
              {k}
            </Text>
            <Text size="text-base" className="font-medium" color="text-black-dark">
              {v}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
};

const ItemTable = ({ loading, items = [] }) => {
  const data = useMemo(() => {
    return items.map((i) => {
      const { quantity, desInPercentage, taxInPercentage, unitPriceBeforeTax, totalPrice } = i;
      return {
        ...i,
        ordinal: (
          <Text size="text-md" strong>
            {i.ordinal}
          </Text>
        ),
        name: (
          <Text size="text-md" strong>
            {i.name}
          </Text>
        ),
        quantity: (
          <Text size="text-md whitespace-nowrap" strong>
            {formatNumberToMoney(quantity, true, true)}
          </Text>
        ),
        unitPrice: (
          <Text size="text-md whitespace-nowrap" strong>{`$ ${formatNumberToMoney(
            unitPriceBeforeTax,
            true,
            true
          )}`}</Text>
        ),
        desInPercentage: (
          <Text size="text-md whitespace-nowrap" strong>{`% ${desInPercentage}`}</Text>
        ),
        taxInPercentage: (
          <Text size="text-md whitespace-nowrap" strong>{`% ${taxInPercentage}`}</Text>
        ),
        total: (
          <Text size="text-md whitespace-nowrap" strong>{`$ ${formatNumberToMoney(
            totalPrice,
            true,
            true
          )}`}</Text>
        ),
      };
    });
  }, [items]);
  const columns = [
    {
      key: "ordinal",
      text: "No.",
      align: "left",
      width: "w-15",
      classNames: "bg-pelorous whitespace-nowrap",
      custom: true,
    },
    {
      key: "name",
      text: "Item",
      align: "left",
      classNames: "bg-pelorous whitespace-nowrap",
      custom: true,
    },
    {
      key: "quantity",
      text: lang.quantity,
      align: "center",
      width: "w-15",
      classNames: "bg-pelorous whitespace-nowrap",
      custom: true,
    },
    {
      key: "unitPrice",
      text: lang.unitPrice,
      align: "center",
      custom: true,
      classNames: "bg-pelorous whitespace-nowrap",
    },
    {
      key: "desInPercentage",
      text: `% ${lang.discount}`,
      align: "center",
      width: "w-20",
      custom: true,
      classNames: "bg-pelorous whitespace-nowrap",
    },
    {
      key: "taxInPercentage",
      text: "TAX %",
      align: "center",
      width: "w-20",
      custom: true,
      classNames: "bg-pelorous whitespace-nowrap",
    },
    {
      key: "total",
      text: lang.total,
      align: "center",
      custom: true,
      classNames: "bg-pelorous whitespace-nowrap",
    },
  ];
  return (
    <div>
      <DataTable
        loading={loading}
        data={data}
        pageable={false}
        className="border-b border-solid border-gray-lightest"
        headerColor="text-white"
        columns={columns}
      />
    </div>
  );
};

const Line = ({ title, text, className = "", bold = false }) => {
  return (
    <div
      className={classNames(
        "flex justify-between p-4 border-b border-solid border-white-darker last:border-b-0",
        className
      )}
    >
      <div className={classNames("text-md", bold ? "font-bold" : "font-medium")}>{title}</div>
      <div className={classNames("text-md", bold ? "font-bold" : "font-medium")}>{text}</div>
    </div>
  );
};

const renderState = (state) => {
  const text = prettifyInvoiced(state);
  let color = "bg-f3f2f1 text-gray border-gray";

  switch (state) {
    case InvoicedSiigo.Unbill:
      color = "bg-f3f2f1 text-gray border-gray";
      break;
    case InvoicedSiigo.SiigoAccepted:
      color = "bg-blue-lightest text-blue border-blue";
      break;
    case InvoicedSiigo.DianAccepted:
      color = "bg-green-lighter text-green-darker border-green-darker";
      break;
    case InvoicedSiigo.DianError:
      color = "bg-faebed text-red-lighter border-red-lighter";
      break;
    default:
      color = "bg-faebed text-red-lighter border-red-lighter";
  }

  return (
    <div
      className={classNames(
        "h-8 border border-solid inline-flex justify-center items-center gap-2 px-3 rounded-lg text-md whitespace-nowrap",
        color
      )}
    >
      {text}
    </div>
  );
};

export default InvoiceDetail;
